.modalBackgroundkh{
    width:100%;
height:100%;
position: fixed;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(152, 161, 142, 0.911);
z-index:9999999;
padding:10px;
}


.modalContainerkh{
    max-width:700px;
    height:90%;
  font-family: 'Montserrat';
    border-radius: 13px;
    background-color: white;
    
    box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding:10px;
    font-family: 'Montserrat';font-size: 12px;

}

.modalContainerkh p{
    font-weight: 600;
    text-align: center;
}


.titleCloseBtnkh{
     padding:0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
}

 



.titleCloseBtnkh button{
background-color: transparent;
border:none;
font-size: 30px;
cursor: pointer;
color:black;
 

}

.modalBackgroundkh .jdkk{
    font-size: 12px;
    padding:10px;
    overflow-y: scroll;
    
}

.modalBackgroundkh table tr td{ 
    padding:5px;
    border-bottom: 1px rgb(199, 199, 199) solid;;
}




@media (max-width: 480px) {
    
 
.modalContainerkh{
    width:100%;
    height:80%;
  font-family: 'Montserrat';
    border-radius: 13px;
    background-color: white;
    
    box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding:5px;
    font-family: 'Montserrat';font-size: 12px;

}

.modalContainerkh p{
    font-weight: 600;
    text-align: center; 
}


.titleCloseBtnkh{
     padding:0px;
     
    justify-content: flex-end;
    align-items: center;
    
}

 



.titleCloseBtnkh button{
background-color: transparent;
border:none;
font-size: 30px;
cursor: pointer;
color:black;
 

}

.modalBackgroundkh .jdkk{
    font-size: 12px;
    padding:5px;
    overflow-y: scroll;
    width:100%;
}

.modalBackgroundkh table  { 
   width:100%;
  
}
.modalBackgroundkh table tr td { 
    padding:5px;
    font-size: 10px;
  
   
 }

  }

