

.item_8{
   width:100%;
   height:auto; background:white;
                           }
  
                        
  
                        .item_8  .section_81{    /*     GRID    */
                          max-width:1170px;
                          height:100%;
                          margin:0 auto;
                          display: grid; 
                          height:100%;
                          grid-template-columns: 1fr  ;  
                          grid-template-rows: auto auto auto auto auto;
                          padding:30px;
                          grid-gap: 5px;
                       
                          
                                                                           }
      
  .item_8 .tiob{
    max-width:1170px;
margin:5px auto;
 text-align: right;
 
  }

  .item_8 .tiob span {
     width:auto;
   
     margin-top:-70px;
    color:#3d3d3d;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 500;
    padding:7px;
  }

  .item_8 .tiob span a{
 
    color:#ff8707;
    font-family: 'Montserrat';
    font-weight: 500;
    padding:7px;
  }



  .item_81{
    grid-column-start:1;
    grid-column-end:2;  
    grid-row-start: 2;
    grid-row-end: 3;
    margin-left:40px;
   
    height:auto;
  }
  
  .item_81 h1{   /* Барномасозӣ */
     
    color: #3d3d3d;
    font-family: 'Oswald', sans-serif;
    line-height: 1.15;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    padding-top:20px;
    padding-bottom:20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-left:-10px;
    
  }
  
  .item_81 .text_81{
    color:white;
     padding-left:5%;
    height:auto;
    border-left: 3px  #1d2636 solid;
     
    font-family:'Montserrat';  
    color:  #1d2636;   
  }
   


  .item_81 .text_81 p{
    font-size: 14px;
  }
  .text_81 ul{
    list-style: none;
    display: flex;   
  }
  .text_81 ul li{
    padding:5px;font-size: 14px;
  }
  .text_81 ul li.Djobs{
    width:110px;
    height:120px;
     border:1px solid #3d3d3d;
  }
  .text_81 .pleft{
    width:80%;
    padding:10px;
    color:#3d3d3d;font-size: 14px;
  }
  .text_81 .pleft a{
    color:white;
    font-style: italic;
    font-size: 14px;
    color:#3d3d3d;
  }
  
  
  
  .item_82{
  grid-column-start:1;
  grid-column-end:2;  
  grid-row-start: 3;
  grid-row-end: 4;
  
  height:auto;
  margin-left:20px;
  color:white;
  }
  
  .text_82{
    
    height:60px;
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  
  }
   .item_82 .text_82 .left_82{
    font-weight: 600;
    display: flex;
     align-items: center;
    justify-content: center;
    background-color:     #1d2636; ; 
    color:rgb(255, 255, 255);
    height:100%;
    width:34%;
     
     border:2px solid    #1d2636; 
   }
  
  .item_82 .text_82 .center_82{
    background-color: rgb(255, 135, 7);
    height:100%;
    width:33%;
    color:#ffffff;
    font-weight: 600;
    display: flex;
     align-items: center;
    justify-content: center; 
   }
   .item_82 .text_82 .right_82{
     color: rgb(255, 135, 7);
    font-weight: 600;
    display: flex;
     align-items: center;
    justify-content: center;
    height:100%;
    width:33%; 
    border:2px rgb(255, 135, 7) solid;
   }
  
  
  .text_82 .FaReg{
    margin-right:15px;
    color:  rgb(255, 135, 7);
    font-size: 26px;

  }
  
  
  
  
  
  .item_83{
    grid-column-start:1;
    grid-column-end:2;  
    grid-row-start: 4;
    grid-row-end: 5;
    height:auto;
    padding:10px;
    margin-left:30px;
    }
  
    .item_83 .text_830{   /*  Frontend .....*/
      height:auto;
      padding:15px;
      border-left: 3px  #1d2636 solid;
      color:#3d3d3d;
      display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
     font-family: 'Montserrat';font-size: 14px;
    }
  a.az{
    color:rgb(255, 135, 7);
    text-decoration: none;
    font-weight: 600;
  }
  .item_83 .text_83{
    height:10px;
    border-left: 3px  #1d2636 solid;
    
    color:#3d3d3d;
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
   
  }
   
  
  
  
      .item_83 .text_84{
        height:auto;
        border-left: 3px  #1d2636 solid;
        padding:15px;
      display: flex;
      flex-direction: row;
       
      } 
      
      .item_83 .text_84 .zb_l{
        width:45%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding:10px;
      }
  
      .item_83 .text_84 .zb_grid{    /*   GRID    */
        height:auto;
        width:auto;
        display: grid; 
        grid-template-columns: repeat(5, 1fr); 
        grid-template-rows: repeat(5, 1fr);
        grid-gap: 5px;
       align-items: center;
       text-align: center;
  padding:10px;
  font-weight: 600;
  
  
        
      }
  
      .item_83 .text_84 .zb_grid .item{
    height:72px;
    width:72px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    border:2px solid #b8b7b7;
    border-radius: 5px;
    }
    .item_83 .text_84 .zb_grid .item a{
     font-size: 10px;
     font-weight: 500;
     user-select: none;
     font-family: 'Montserrat';
    }
    
    .zb_grid input[type="checkbox"]{
    position: absolute;
    border-radius: 5px;
    -webkit-appearance: none;
    height:70px;
    width:70px;
    cursor: pointer;
    
    box-shadow: -10px -10px 15px  rgba(255, 255, 255, 0.5), 10px 10px 15px rgba(70,70,70,0.12);
  
    
  }
  
  
  
  .zb_grid input[type="checkbox"]:checked{
    box-shadow: 
    -10px -10px 15px  rgba(255, 255, 255, 0.5), 
    10px 10px 15px rgba(70,70,70,0.12),
    inset -10px -10px 15px  rgba(255,255,255,0.5), 
    inset 10px 10px 15px rgba(70,70,70, 0.12);
    border:8px solid #ececec;
    border-radius: 5px;
    
  }
  
  
  
  
  
  
  
      .item_83 .text_84 .zb_r{
         
        height:auto;
        width:55%;
      
         
        padding:10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        cursor: pointer;
        border-radius: 5px;
        box-shadow: -2px -2px 7px  rgba(255, 255, 255, 0.5), 1px 1px 1px rgba(70,70,70,0.12);
      }
  
   


      @media (max-width: 990px) {
        

  
       .item_83 .text_84{
        height:auto;
       
        padding:5px;
      display: flex;
      flex-direction: row;
     
      }
      
      .item_83 .text_84 .zb_l{
        width:45%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        padding:10px;
        
      }
  
      .item_83 .text_84 .zb_grid{    /*   GRID    */
        height:auto;
        width:auto;
        display: grid; 
        grid-template-columns: repeat(4, 1fr); 
        grid-template-rows: repeat(7, 1fr);
        grid-gap: 5px;
       align-items: center;
       text-align: center;
  padding:10px;
  font-weight: 600;
        
  
        
      }
  
      .item_83 .text_84 .zb_grid .item{
    height:72px;
    width:72px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    border:2px solid #b8b7b7;
    border-radius: 5px;
    }
    .item_83 .text_84 .zb_grid .item a{
     font-size: 10px;
    
     user-select: none;
     font-family: 'Montserrat';
    }
    
   
  
  
 
  
  
  
  
  
  
  
      .item_83 .text_84 .zb_r{
         
        height:auto;
        width:55%;
        
         
        padding:10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        cursor: pointer;
        border-radius: 5px;
        box-shadow: -2px -2px 7px  rgba(255, 255, 255, 0.5), 1px 1px 1px rgba(70,70,70,0.12);
      }
  
  











      }


      @media (max-width: 870px) {

        .item_8 .section_8{                                                                       
                  
          
         height:100%;                                      
          width:100%;                                                  
        }

        .item_8  .section_81{    /*     GRID    */
          width:100%;
          height:100%;
          margin:0 auto;
          display: grid; 
          height:100%;
          grid-template-columns: 1fr  ;  
          grid-template-rows: auto auto auto auto auto;
          padding:30px;
          grid-gap: 5px;
         
          
                                                           }

  
        .item_82{
          grid-column-start:1;
          grid-column-end:2;  
          grid-row-start: 3;
          grid-row-end: 4;
         
          height:auto;
          margin-left:10px;
          
          color:white;
          }
          
          .text_82{
            width:100%;
            height:60px;
            display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          
          }



        .item_82 .text_82 .left_82{
          font-weight: 600;
          display: flex;
           align-items: center;
          justify-content: center;
          
          color:rgb(255, 255, 255);
          height:100%;
          width:34%;
        
           
         }
        
        .item_82 .text_82 .center_82{
          background-color: rgb(255, 135, 7);
          height:100%;
          width:33%;
          color:#ffffff;
          font-weight: 600;
          display: flex;
           align-items: center;
          justify-content: center; 
         }
         .item_82 .text_82 .right_82{
           color: rgb(255, 135, 7);
          font-weight: 600;
          display: flex;
           align-items: center;
          justify-content: center;
          height:100%;
          width:33%; 
          border:2px rgb(255, 135, 7) solid;
         }
        
        
         

 
 



     .item_83 .text_84{
      height:auto;
      
      padding:5px;
    display: flex;
    flex-direction: row;
   
    } 
    
    .item_83 .text_84 .zb_l{
      width:50%;
      display: flex;
      justify-content: center;
      align-items: center;
      
      padding:10px;
      
    }

    .item_83 .text_84 .zb_grid{    /*   GRID    */
      height:auto;
      width:auto;
      display: grid; 
      grid-template-columns: repeat(4, 1fr); 
      grid-template-rows: repeat(7, 1fr);
      grid-gap: 5px;
     align-items: center;
     text-align: center;
padding:10px;
font-weight: 600;
     

      
    }

 


 






    .item_83 .text_84 .zb_r{
       
      height:auto;
      width:50%;
      
       
      padding:0px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      cursor: pointer;
      border-radius: 5px;
      box-shadow: -2px -2px 7px  rgba(255, 255, 255, 0.5), 1px 1px 1px rgba(70,70,70,0.12);
    }

















      }


      @media (max-width: 768px) {

        .text_81 ul li.Djobs{
          width:110px;
          height:110px;
           border:1px solid #3d3d3d;
            margin-left:-30px;
        }
        .text_81 .pleft{
          width:100%;  
          padding:10px;
          color:#3d3d3d; 
        }
        .text_81 .pleft a{
          color:white;
          font-style: italic;
          
          color:#3d3d3d;
        }


         


     .item_83 .text_84{
      height:auto;
   
      padding:5px;
    display: flex;
    flex-direction: column;
   
    }
    .item_83 .text_84 span{   /*  span  */
 
      transform: rotate(-90deg);
      position:absolute;
      margin-left:-131px;
      margin-top:160px;
  
       
      padding:2px;
      text-transform: none;
      font-family: 'Montserrat', sans-serif;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
 
padding-left:12px;
padding-right:12px;
    }   
    

    .item_83 .text_84 .zb_l{
      width:100%;
      display: flex;
      justify-content: center;
      align-items: center;
      
      padding:10px;
       
    }

    .item_83 .text_84 .zb_grid{    /*   GRID    */
      height:auto;
      width:auto;
      display: grid; 
      grid-template-columns: repeat(7, 1fr); 
      grid-template-rows: repeat(4, 1fr);
      grid-gap: 5px;
     align-items: center;
     text-align: center;
padding:10px;
font-weight: 600;
     
 
      
    }

 


 






    .item_83 .text_84 .zb_r{
       
      height:auto;
      width:90%;
      margin:0 auto;
       
      padding:0px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      cursor: pointer;
      border-radius: 5px;
      box-shadow: -2px -2px 7px  rgba(255, 255, 255, 0.5), 1px 1px 1px rgba(70,70,70,0.12);
    }



 
 


 
      }




      @media (max-width: 700px) {

      .item_83 .text_84 .zb_grid{    /*   GRID    */
        height:auto;
        width:auto;
        display: grid; 
        grid-template-columns: repeat(5, 1fr); 
        grid-template-rows: repeat(5, 1fr);
        grid-gap: 5px;
       align-items: center;
       text-align: center;
  padding:10px;
  font-weight: 600;
      
   
      }

     

      }
  
















      @media (max-width: 620px) {
       
  
    
    
    .item_81{
      grid-column-start:1;
      grid-column-end:2;  
      grid-row-start: 2;
      grid-row-end: 3;
      margin-left:40px;
     
      height:auto;
    }
    
    .item_81 h1{
     
       
      line-height: 1.15;
      font-weight: 700;
      background-position: center center;
      border-color: transparent;
      padding-top:20px;
      padding-bottom:20px;
      display: flex;
      align-items: center;
      
      margin-left:-10px;
      
    }
    
    .item_81 .text_81{
     
       padding-left:5%;
      height:auto;
    
    
      font-family:'Montserrat';  
 
       
    }
     
    .text_81 ul{
      list-style: none;
      display: flex;   
      
    
    }
    .text_81 ul li{
      padding:5px;
      
       
    }
    .text_81 ul li.Djobs{
      width:110px;
      height:110px;
       border:1px solid #3d3d3d;
        margin-left:-30px;
    }
    .text_81 .pleft{
      width:100%;  
      padding:10px;
      color:#3d3d3d;font-size: 14px;
    }
    .text_81 .pleft a{
      color:white;
      font-style: italic;
      font-size: 14px;
      color:#3d3d3d;
    }
    

    .item_83 .text_84 .zb_grid{    /*   GRID    */
      height:auto;
      width:auto;
      display: grid; 
      grid-template-columns: repeat(5, 1fr); 
      grid-template-rows: repeat(5, 1fr);
      grid-gap: 5px;
     align-items: center;
     text-align: center;
padding:10px;
font-weight: 600;
      
 
    }

    .item_83 .text_830{
      height:auto;
      padding:15px;
     
      color:#3d3d3d;
      display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
     font-family: 'Montserrat';
     font-size: 14px;
    }

    .item_83 .text_85 .h2{
      float:right;
    padding:20px;
    color:#3d3d3d; 
    border:none;  
    width:100%;
    font-family: 'Montserrat' ;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
   } 
    
      }


      @media (max-width: 550px) {


        .item_8 .tiob{
          width:auto;
      margin:5px auto;
      margin-left:-20px;
       text-align: left;
       
        }

        .item_8 .tiob span {
          width:auto;
         
          margin-top:-70px;
         color:#3d3d3d;
         font-size: 12px;
         font-family: 'Montserrat';
         font-weight: 500;
         padding:7px;
       }
     
       .item_8 .tiob span a{
      
         color:#ff8707;
         font-family: 'Montserrat';
         font-weight: 500;
         padding:7px;
       }








.item_8 .section_81{
   
  padding:10px;
}



      .item_83 .text_84 .zb_grid{    /*   GRID    */
        height:auto;
        width:auto;
        display: grid; 
        grid-template-columns: repeat(4, 1fr); 
        grid-template-rows: repeat(7, 1fr);
        grid-gap: 5px;
       align-items: center;
       text-align: center;
  padding:10px;
  font-weight: 600;
        
   
      }

      .item_83 .text_84 .zb_r{
       
        height:auto;
        width:95%;
        margin:0 auto;
         
        padding:0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        cursor: pointer;
        border-radius: 5px;
        box-shadow: -2px -2px 7px  rgba(255, 255, 255, 0.5), 1px 1px 1px rgba(70,70,70,0.12);
      }
 

    }

      @media (max-width: 480px) {
       

        .item_82 .text_82 .left_82{
          font-weight: 600;
          display: flex;
           align-items: center;
          justify-content: center;
          
          color:rgb(255, 255, 255);
          height:100%;
          width:34%;
 
    
           font-size: 16px;
         }
        
        .item_82 .text_82 .center_82{
          background-color: rgb(255, 135, 7);
          height:100%;
          width:33%;
          color:#ffffff;
          font-weight: 600;
          font-size: 16px;
          display: flex;
           align-items: center;
          justify-content: center;
         
         }


         .text_82 .FaReg{
          margin-right:5px;
          color:  rgb(255, 135, 7);
          font-size: 26px;
      
        }
         .item_82 .text_82 .right_82{
           color: rgb(255, 135, 7);
          font-weight: 600;
          display: flex;font-size: 14px;
           align-items: center;
          justify-content: center;
          height:100%;
          width:33%; 
           
          border:2px rgb(255, 135, 7) solid;
         }
        
        
      
      }





      @media (max-width: 430px) {

      .item_83 .text_84 .zb_grid{    /*   GRID    */
        height:auto;
        width:auto;
        display: grid; 
        grid-template-columns: repeat(3, 1fr); 
        grid-template-rows: repeat(9, 1fr);
        grid-gap: 5px;
       align-items: center;
       text-align: center;
  padding:10px;
  font-weight: 200;
       
   
      }

      .item_83 .text_84 .zb_r{
       
        height:auto;
        width:95%;
        margin:0 auto;
         
        padding:0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        cursor: pointer;
        border-radius: 5px;
        box-shadow: -2px -2px 7px  rgba(255, 255, 255, 0.5), 1px 1px 1px rgba(70,70,70,0.12);
      }

 

    }

 