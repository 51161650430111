 
.modalBackgroundmm{
    background-image: url('../img/dmt.jpg'); 
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.863) 0 100%), url('../img/dmt.jpg');
    background-size: cover; 
        width:100%;
    height:100%;
  padding:15px;
display: flex;
 justify-content: center;
 
    
}

.text-mm{
    text-transform: uppercase;
    font-family: 'Oswald';
     width:100%;
      font-size: 16px;
      text-align: center;
      margin-top:50px;
      color: #3d3d3d; 
 }
 





.modalmContainermm{
    height:auto;
     width:780px;
 
    border-radius: 5px;
    padding:10px;
    padding-bottom:30px;
    
}






.titleCloseBtnmm{
    display: flex;
    justify-content:flex-end; /* горизонт */
    
    align-items:center; /* вертикал */

}


.titleCloseBtnmm button{
background-color: transparent;
border:none;
font-size: 24px;
cursor: pointer;
padding:0px;
margin-top:-7px;
display: flex;
justify-content:right; /* горизонт */
 
width:10%;
 float:right;
}



.titleCloseBtnmm button   {
    border:1px rgb(101, 101, 101) solid;
    display: flex;
   justify-content: center;
   align-items: center;
   padding:1px;
   padding-bottom: 4px;
    width:50px;
    right:30px;
   margin:0px;
   font-size: 28px;
   font-weight: 100;
   position: fixed;
}






.Contmm{
    overflow: none;
    
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
    width:100%;
    height:auto
   }

div.dp{
    padding:7px;
    width:50%;
     
}
.parch {
    width:100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:600px;
    padding:15px;
}


.parch_img{
    width:180px;
    height:150px;
    padding:10px;
    
}

.Contmm p{
    font-size: 13px;
    text-align: justify;
    text-indent: 20px;
    font-family: 'Montserrat';
}

.Contmm p.pa{
    font-size: 13px;
    text-align: justify;
    text-indent: 0px;
    font-family: 'Montserrat';
    
}


a.red{
    color:red;
    text-decoration: none;
    font-weight: 600;
}

a.gr{
    color:green;
    text-decoration: none;
    font-weight: 600;
}

a.wh{
    color:rgb(255, 255, 255);
    text-decoration: none;font-weight: 600;
    text-shadow: 1px 1px 2px rgb(14, 13, 13);
}


 .nish {
    width:100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height:600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    padding:15px;

 }

 .nish_img{
    width:150px;
    height:150px;
    padding:10px;
    margin:0 auto;
}



 .sm {
    width:100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height:600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:15px;
 }

 .sm_p p{
    font-size: 12px;
     
    font-weight: 500;
    font-style: italic;
 }
 .konst {
    width:100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:20px;
    height:600px;
 }


 .konst_img{
    width:260px;
    height:180px;
    padding:10px;
    margin:0 auto;
}


@media (max-width:990px){
    .modalmContainermm{
        height:auto;
         width:780px;
     margin-top:70px;
        border-radius: 5px;
        padding:10px;
        padding-bottom:30px;
        
    }  



    .text-mm{
        text-transform: uppercase;
        font-family: 'Oswald';
         width:100%;
          font-size: 16px;
          text-align: center;
          margin-top:10px;
          color: #3d3d3d; 
     }
}
 





@media (max-width: 770px) {

 
.modalBackgroundmm{
    background-image: url('../img/dmt.jpg'); 
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.863) 0 100%), url('../img/dmt.jpg');
    background-size: cover; 
        width:100%;
    height:100%;
  padding:15px;
display: flex;
 justify-content: center;
 
    
}







.modalmContainermm{
    height:auto;
     width:650px;
 
    border-radius: 5px;
    padding:0px;
    padding-bottom:30px;
    
}



.titleCloseBtnmm{
    display: flex;
    justify-content:flex-end; /* горизонт */
    
    align-items:center; /* вертикал */

}


 



 


.text-mm{
   text-transform: uppercase;
   font-family: 'Oswald';
    width:100%;
     font-size: 18px;
     text-align: center;
     margin-top:5px;
}


.Contmm{
    overflow: none;
   
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: none;
   flex-direction: column;
    width:100%;
    height:auto
   }

div.dp{
    padding:7px;
    width:70%;
     height:auto;
}
.parch {
    width:100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:auto;
    padding:20px;
}


.parch_img{
    width:180px;
    height:150px;
    padding:10px;
    
}

.Contmm p{
    font-size: 13px;
    text-align: justify;
    text-indent: 20px;
    font-family: 'Montserrat';
}

.Contmm p.pa{
    font-size: 13px;
    text-align: justify;
    text-indent: 0px;
    font-family: 'Montserrat';
    
}


a.red{
    color:red;
    text-decoration: none;
    font-weight: 600;
}

a.gr{
    color:green;
    text-decoration: none;
    font-weight: 600;
}

a.wh{
    color:rgb(255, 255, 255);
    text-decoration: none;font-weight: 600;
    text-shadow: 1px 1px 2px rgb(14, 13, 13);
}


 .nish {
    width:100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height:auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    padding:20px;

 }

 .nish_img{
    width:150px;
    height:150px;
    padding:10px;
    margin:0 auto;
}



 .sm {
    width:100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height:auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:20px;
 }

 .sm_p p{
    font-size: 12px;
     
    font-weight: 500;
    font-style: italic;
 }
 .konst {
    width:100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:20px;
    height:auto;
 }


 .konst_img{
    width:260px;
    height:180px;
    padding:10px;
    margin:0 auto;
}


}




@media (max-width: 540px) {

 
    .modalBackgroundmm{
        background-image: url('../img/dmt.jpg'); 
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.863) 0 100%), url('../img/dmt.jpg');
        background-size: cover; 
            width:100%;
        height:100%;
      padding:15px;
    display: flex;
     justify-content: center;
     
        
    }
    
    
    
    
    
    
    
    .modalmContainermm{
        height:auto;
         width:100%;
     
        border-radius: 5px;
        padding:0px;
        padding-bottom:30px;
        
    }
    
    
    
    .titleCloseBtnmm{
        display: flex;
        justify-content:flex-end; /* горизонт */
        
        align-items:center; /* вертикал */
    
    }
    
    
   
    
    
    
    
    
    .text-mm{
       text-transform: uppercase;
       font-family: 'Oswald';
        width:100%;
         font-size: 18px;
         text-align: center;
         margin-top:5px;
    }
    
    
    .Contmm{
        overflow: none;
       
       display: flex;
       justify-content: center;
       align-items: center;
       flex-wrap: none;
       flex-direction: column;
        width:100%;
        height:auto
       }
    
    div.dp{
        padding:7px;
        width:80%;
         height:auto;
    }
    .parch {
        width:100%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height:auto;
        padding:20px;
    }
    
    
    .parch_img{
        width:180px;
        height:150px;
        padding:10px;
        
    }
    
    .Contmm p{
        font-size: 13px;
        text-align: justify;
        text-indent: 20px;
        font-family: 'Montserrat';
    }
    
    .Contmm p.pa{
        font-size: 13px;
        text-align: justify;
        text-indent: 0px;
        font-family: 'Montserrat';
        
    }
    
    
    a.red{
        color:red;
        text-decoration: none;
        font-weight: 600;
    }
    
    a.gr{
        color:green;
        text-decoration: none;
        font-weight: 600;
    }
    
    a.wh{
        color:rgb(255, 255, 255);
        text-decoration: none;font-weight: 600;
        text-shadow: 1px 1px 2px rgb(14, 13, 13);
    }
    
    
     .nish {
        width:100%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        height:auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        padding:20px;
    
     }
    
     .nish_img{
        width:150px;
        height:150px;
        padding:10px;
        margin:0 auto;
    }
    
    
    
     .sm {
        width:100%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        height:auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:20px;
     }
    
     .sm_p p{
        font-size: 12px;
         
        font-weight: 500;
        font-style: italic;
     }
     .konst {
        width:100%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:20px;
        height:auto;
     }
    
    
     .konst_img{
        width:260px;
        height:180px;
        padding:10px;
        margin:0 auto;
    }
    
    
    }



    
@media (max-width: 480px) {
 
    .text-mm{
        text-transform: uppercase;
        font-family: 'Oswald';
         width:100%;
          font-size: 16px;
          text-align: center;
          margin-top:5px;
     }
     
.Contmm p{
    font-size: 12px;
    text-align: justify;
    text-indent: 20px;
    font-family: 'Montserrat';
}

.Contmm p.pa{
    font-size: 12px;
    text-align: justify;
    text-indent: 0px;
    font-family: 'Montserrat';
    
}
     
     
    
    div.dp{
        padding:7px;
        width:95%;
         height:auto;
    }
}



@media (max-width: 420px) {
 
   
     
    
         
        
        div.dp{
            padding:7px;
            width:100%;
             height:auto;
        }
    }


    @media (max-width:420px){
        .modalmContainermm{
            height:auto;
             width:100%;
         
            border-radius: 5px;
            padding:10px;
            padding-bottom:30px;
            
        }  
    }