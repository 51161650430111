.kafedra{
width:100%;
height:400px;
background:white;

}

.item_1{
 height:100%;
 max-width:1170px;
margin:0 auto;
    }
    .item_1 .lr{
        max-width:1170px;
        padding-top:30px;
        padding-bottom:30px;
        margin:0 auto;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    
    
    
    
    .lr .left{
        width:55%;
       position: relative;
     
         padding:16px;
        
    }
    



.lr .right{
    width:45%;
    padding:16px;
    position:relative;
   /*  animation: 1s showRight ease ;*/
     height:auto;
     
}
/*@keyframes showRight {
 
  from {right:-100%;}
  
  to {right:0;}
  
  }*/

.left h1{
  position: relative;
     
  color: #111111;color: #3d3d3d;
    font-size: 16px;
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom:30px;
     
    text-transform: uppercase;
    }
    .left    p {
      color: #3d3d3d;
        line-height: 28px;
        font-size:14px;
      
        font-weight: 400;
        margin: 0px;
        font-family: 'Montserrat', sans-serif;
       
         position: relative;
        text-align: justify;
           margin-bottom:30px;
      }

       
      
      
     


    
      .left a.aleft {
        color: #3d3d3d; 
        
        
        margin-top:5px;
        padding:8px 18px 8px 18px;
        font-size: 15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        text-decoration: none;
        margin-left:0px;
        transition: all 0.5s;
        border :1px solid #3d3d3d;
         
      }
      .left a.aleft:hover{
        
        border-radius: 0px;
        margin-top:5px;
        padding:8px 18px 8px 18px;
        font-size: 15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        text-decoration: none;
        margin-left:0px;
        background: none;
        letter-spacing: 2px;
        transition: all 0.5s;
        border-top:1px solid  #3d3d3d;
        border-bottom:1px solid #3d3d3d
      }



     
    @media (max-width: 1024px) {
 
        .item_1{
            grid-column-start:1;
            grid-column-end:2;
            grid-row-start:1;
            grid-row-end:2;
             padding:5px;
             background-color: #ffffff;
             height:auto;
            }
            
            
            
            
            .left h1{
                color: #3d3d3d;
               
                
                font-size: 18px;
                font-family: 'Oswald', sans-serif;
                font-weight: 700;
                margin-top: 30px;
                 margin-bottom:10px;
                text-transform: uppercase;
                }
                .left    p {
                    color: #3d3d3d;
                    line-height: 24px;
                    font-size:16px;
                    
                    font-weight: 400;
                    margin: 0px;
                    font-family: 'Montserrat', sans-serif;
                     
                    text-align: justify;
                       margin-bottom: 20px;
                  }
                }
                  

                  @media (max-width: 990px) {     
    .item_1{
        grid-column-start:1;
        grid-column-end:2;
        grid-row-start:1;
        grid-row-end:2;
         padding:5px;
         background-color: #ffffff;
         height:auto;
               }



        .item_1 .lr{
            max-width:990px;
            
        }
        



    }


    @media (max-width: 870px) {
        .item_1{
            grid-column-start:1;
            grid-column-end:2;
            grid-row-start:1;
            grid-row-end:2;
             padding:5px;
             background-color: #ffffff;
             height:auto;
              
                   }
            .item_1 .lr{
                max-width:870px;
                margin:0px auto;
                display: flex;
                flex-flow: wrap; 
                padding:0px;
            }
            .lr .left{
                width:100%;
                padding:25px 0px 25px 0px;
                 order:2; 
                
              
            }
            .lr .right{
                width:100%;
                 
                padding:10px;
                 height:auto;
                 order:1;
            }
            
            
            .lr .left h1{
            
                
                font-size: 18px;
                font-family: 'Oswald', sans-serif;
                font-weight: 700;
                
                 margin:5px;
                 margin-left:10px;
                text-transform: uppercase;
    
                }
                .lr   .left    p {
                    color: #3d3d3d;
                    line-height: 24px;
                    font-size:16px;
                    
                    font-weight: 400;
                    margin: 10px;
                    font-family: 'Montserrat', sans-serif;
                     
                    text-align: justify;
                       margin-bottom: 20px;
                        
                  }
                
                  .lr   .left a.aleft {
                  
                    position: relative;
                    padding:8px;
                    font-size: 14px;
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                    text-decoration: none;
                    margin:10px;
                    
                  
                  }
                  .lr   .left a.aleft:hover{
                     
                    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                    transition: 1s;
                  }
            }



            
@media (max-width: 768px) {
    .item_1{
        grid-column-start:1;
        grid-column-end:2;
        grid-row-start:1;
        grid-row-end:2;
         padding:5px;
       
         height:auto;
               }
               .item_1 .lr{
                max-width:768px;
                margin:0 auto;
                display: flex;
                flex-flow: wrap;
            }
            .left{
                width:100%;
                padding:10px;
                 order:2;
                 
              
            }
            .right{
                width:100%;
                 
                padding:10px;
                 height:auto;
                 order:1;
            }
        
        
        .left h1{
           
            
            font-size: 18px;
            font-family: 'Oswald', sans-serif;
            font-weight: 700;
            
             margin:0px;
             margin-left:10px;
            text-transform: uppercase;
            }
            .left    p {
                color: #3d3d3d;
                line-height: 24px;
                font-size:16px;
                
                font-weight: 400;
                margin: 10px;
                font-family: 'Montserrat', sans-serif;
                 
                text-align: justify;
                   margin-bottom: 8px;
              }
            
              .left a.aleft {
               
                position: relative;
                padding:8px;
                font-size: 14px;
                font-family: 'Montserrat', sans-serif;
                font-weight: 400;
                text-decoration: none;
                margin:10px;
               
              
              }
              .left a.aleft:hover{
                 
                box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                transition: 1s;
              }
            }
    





            @media (max-width: 640px) {
            .item_1{
                grid-column-start:1;
                grid-column-end:2;
                grid-row-start:1;
                grid-row-end:2;
                 padding:5px;
                
                 height:auto;
                       }
                       .item_1 .lr{
                        max-width:640px;
                        margin:0px auto;
                        display: flex;
                        flex-flow: wrap;
                    }
                    .lr .left{
                        width:100%;
                        padding:20px 0 20px 0;
                         order:2;
                         
                      
                    }
                    .lr .right{
                        width:100%;
                         
                        padding:5px;
                         height:auto;
                         order:1;
                    }
                
                
                    .lr .left h1{
                    
                    
                    font-size: 16px;
                    font-family: 'Oswald', sans-serif;
                    font-weight: 700;
                    
                     margin:5px;
                     margin-left:10px;
                    text-transform: uppercase;
                    }
                    .lr .left    p {
                        color: #3d3d3d;
                        line-height: 24px;
                        font-size:14px;
                        
                        font-weight: 400;
                        margin: 10px;
                        font-family: 'Montserrat', sans-serif;
                         
                        text-align: justify;
                           margin-bottom: 15px;
                      }
                    
                      .lr .left a.aleft {
                       
                        position: relative;
                        padding:8px;
                        font-size: 14px;
                        font-family: 'Montserrat', sans-serif;
                        font-weight: 400;
                        text-decoration: none;
                        margin:0px;
                        margin-right:10px;
                      float:right;
                       
                      width:auto;
                      }
                      .lr .left a.aleft:hover{
                         
                        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                        transition: 1s;
                      }
                    }


                    

@media (max-width: 486px) {
    .item_1{
        grid-column-start:1;
        grid-column-end:2;
        grid-row-start:1;
        grid-row-end:2;
         padding:0px;
        
         height:auto;
               }
               .item_1 .lr{
                max-width:480px;
                margin:0px auto;
                display: flex;
                flex-flow: wrap;
            }
            .item_1 .lr .left{
                width:100%;
                padding:10px;
                 order:2;
                 
              
            }
            .item_1 .lr   .right{
                width:100%;
                 
                padding:10px;
                 height:auto;
                 order:1;
            }
        
            .item_1 .lr .left h1{
            
            font-size: 16px;
            font-family: 'Oswald', sans-serif;
            font-weight: 700;
            
             margin:0px;
             margin-left:10px;
            text-transform: uppercase;
            }
            .item_1 .lr .left    p {
                color: #3d3d3d;
                line-height: 24px;
                font-size:14px;
                
                font-weight: 400;
                margin: 10px;
                font-family: 'Montserrat', sans-serif;
                 
                text-align: justify;
                   margin-bottom: 8px;
              }
            
              .item_1 .lr .left a.aleft {
            
                position: relative;
                padding:8px;
                font-size: 12px;
                font-family: 'Montserrat', sans-serif;
                font-weight: 400;
                text-decoration: none;
                margin:5px;
               width:auto;
              margin-right:10px;
              }
              .item_1 .lr .left a.aleft:hover{
                 
                box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                transition: 1s;
              }
        
            }



    
    
    
    
    