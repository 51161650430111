 


.section_61{
  width:100%;

 background-color:#122947;
height:100%;                     

}

.section_6{
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;                          
max-width:1170px;
margin:0 auto;
padding:30px;
padding-top:70px;
padding-bottom:100px;
height:100%;

}

.section_6 .s1{
        grid-column-start:1;
         grid-column-end:2;
         animation: 1s show ease;
         position:relative;
          padding:15px;
        
     }

     .section_6 .ico6  {
        display: flex;
        align-items: center;
        justify-content: center; 
         width:72px;                                 
        padding:16px;
        color:#122947;
        border-radius: 50px; 
        
        background-color: #fff;
        font-size: 36px;
     }

     .section_6 .title6{
        display: flex;
       align-items: center; 
        width:100%;                           
         font-weight: bolder;
         text-transform: uppercase;
         font-size: 16px;
         padding:5px;
         font-family: 'Oswald' ;
         padding-top:20px;
         color:#fff;
     }
     .section_6 .text6{
        width:100%;
       color: #fff;
        padding:5px;
        font-size: 14px;
        font-family: 'Montserrat' ;
     }                       

     .section_6 .s2{
             grid-column-start:2;
             grid-column-end:3;
             
             padding-top:100px;
            }
            .section_6 .s3{
                grid-column-start:3;
                grid-column-end:4;
              
                padding:15px;
               }

               .section_6 .s4{
                grid-column-start:4;
                grid-column-end:5;
                
                padding-top:100px;
               }

               .section_6 a{
                transition: 1s;
                text-decoration: none;
                color:#fff;
               
               }
               .section_6 a:hover{
               transition: 1s;
                
               }


               @media (max-width: 1024px) { 
              
                .section_6{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr; 
            max-width:1024px;
            margin:0 auto;padding:30px;
            padding-top:70px;
            padding-bottom:100px;
            
             height:100%;
              }

                .section_6 .s1{
                        grid-column-start:1;
                         grid-column-end:2;
                          
                          padding:15px;
                     }

                     .section_6 .ico6  {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start; 
                         width:70px;                                 
                        padding:16px;
                      
                        border-radius: 50px; 
                     
                        font-size: 35px;
                     }

                     .section_6 .title6{
                        display: flex;
                       align-items: center; 
                        width:100%;                           
                         font-weight: bolder;
                         text-transform: uppercase;
                         font-size: 16px;
                         padding:5px;
                         font-family: 'Oswald' ;
                         padding-top:20px;
                        
                     }
                     .section_6 .text6{
                        width:100%;
               
                        padding:5px;
                        font-size: 14px;
                        font-family: 'Montserrat' ;
                     }                       

                     .section_6 .s2{
                             grid-column-start:2;
                             grid-column-end:3;
                             
                             padding-top:100px;
                            }
                            .section_6 .s3{
                                grid-column-start:3;
                                grid-column-end:4;
                              
                                padding:15px;
                               }

                               .section_6 .s4{
                                grid-column-start:4;
                                grid-column-end:5;
                                
                                padding-top:100px;
                               }

                               .section_6 a{
                                transition: 2s;
                                text-decoration: none;
                        
                               }
                               .section_6 a:hover{
                               transition: 2s;
                              
                               }
 
               }


               @media (max-width: 990px){
           
                .section_6{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr; 
                max-width:990px;
            margin:0 auto;padding:30px;
            padding-top:70px;
            padding-bottom:100px;
            
             height:100%;
              }

                .section_6 .s1{
                        grid-column-start:1;
                         grid-column-end:2;
                          
                          padding:15px;
                     }

                     .section_6 .ico6  {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start; 
                         width:70px;                                 
                        padding:16px;
                    
                        border-radius: 50px; 
                        font-size: 35px;
                     }

                     .section_6 .title6{
                        display: flex;
                       align-items: center; 
                        width:100%;                           
                         font-weight: bolder;
                         text-transform: uppercase;
                         font-size: 16px;
                         padding:5px;
                         font-family: 'Oswald' ;
                         padding-top:20px;
                  
                     }
                     .section_6 .text6{
                        width:100%;
                   
                        padding:5px;
                        font-size: 14px;
                        font-family: 'Montserrat' ;
                     }                       

                     .section_6 .s2{
                             grid-column-start:2;
                             grid-column-end:3;
                             
                             padding-top:100px;
                            }
                            .section_6 .s3{
                                grid-column-start:3;
                                grid-column-end:4;
                              
                                padding:15px;
                               }

                               .section_6 .s4{
                                grid-column-start:4;
                                grid-column-end:5;
                                
                                padding-top:100px;
                               }

                               .section_6 a{
                                transition: 2s;
                                text-decoration: none;}
                             
                               .section_6 a:hover{
                               transition: 2s;
                               
                               } 
 
               }

               @media (max-width: 870px) {    

          
                .section_6{
                display: grid;
                grid-template-columns: 1fr 1fr ; 
                grid-template-rows: 1fr 1fr ; 
                max-width:768px;
            margin:0 auto;padding:20px;
            padding-top:70px;
            padding-bottom:70px;
            
             height:100%;
              }

                .section_6 .s1{
                        grid-column-start:1;
                         grid-column-end:2;
                         grid-row-start:1;
                         grid-row-end:2;
                          padding:15px;
                     }

                     .section_6 .ico6  {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start; 
                         width:60px;                                 
                        padding:12px;
               
                        border-radius: 50px; 
                        font-size: 35px;
                     }

                     .section_6 .title6{
                        display: flex;
                       align-items: center; 
                        width:100%;                           
                         font-weight: bolder;
                         text-transform: uppercase;
                         font-size: 16px;
                         padding:5px;
                         font-family: 'Oswald' ;
                         padding-top:20px;
                        
                     }
                     .section_6 .text6{
                        width:100%;
                     
                        padding:5px;
                        font-size: 16px;
                        font-family: 'Montserrat' ;
                     }                       

                     .section_6 .s2{
                             grid-column-start:2;
                             grid-column-end:3;
                             grid-row-start:1;
                             grid-row-end:2;
                             padding-top:100px;
                            }
                            .section_6 .s3{
                                grid-column-start:1;
                                grid-column-end:2;
                                grid-row-start:2;
                                grid-row-end:3;
                                padding:15px;
                               }

                               .section_6 .s4{
                                grid-column-start:2;
                                grid-column-end:3;
                                grid-row-start:2;
                                grid-row-end:3;
                                
                                padding-top:100px;
                               }

                               .section_6 a{
                                transition: 2s;
                                text-decoration: none;
                            
                               }
                               .section_6 a:hover{
                               transition: 2s;
                              
                               } 
               }


               @media (max-width: 768px) {
                        
        
                .section_6{
                display: grid;
                grid-template-columns: 1fr 1fr ; 
                grid-template-rows: 1fr 1fr ; 
                max-width:768px;
            margin:0 auto;padding:20px;
            padding-top:70px;
            padding-bottom:70px;
            
             height:100%;
              }

                .section_6 .s1{
                        grid-column-start:1;
                         grid-column-end:2;
                         grid-row-start:1;
                         grid-row-end:2;
                          padding:15px;
                     }

                     .section_6 .ico6  {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start; 
                         width:60px;                                 
                        padding:12px;
                   
                        border-radius: 50px; 
                        font-size: 35px;
                     }

                     .section_6 .title6{
                        display: flex;
                       align-items: center; 
                        width:100%;                           
                         font-weight: bolder;
                         text-transform: uppercase;
                         font-size: 16px;
                         padding:5px;
                         font-family: 'Oswald' ;
                         padding-top:20px;
                       
                     }
                     .section_6 .text6{
                        width:100%;
                
                        padding:5px;
                        font-size: 14px;
                        font-family: 'Montserrat' ;
                     }                       

                     .section_6 .s2{
                             grid-column-start:2;
                             grid-column-end:3;
                             grid-row-start:1;
                             grid-row-end:2;
                             padding-top:100px;
                            }
                            .section_6 .s3{
                                grid-column-start:1;
                                grid-column-end:2;
                                grid-row-start:2;
                                grid-row-end:3;
                                padding:15px;
                               }

                               .section_6 .s4{
                                grid-column-start:2;
                                grid-column-end:3;
                                grid-row-start:2;
                                grid-row-end:3;
                                
                                padding-top:100px;
                               }

                               .section_6 a{
                                transition: 2s;
                                text-decoration: none;
                                
                               }
                               .section_6 a:hover{
                               transition: 2s;
                            
                               }}

                               
@media (max-width: 640px) {  
    .section_6{
    display: grid;
    grid-template-columns: 1fr 1fr ; 
    grid-template-rows: 1fr 1fr ; 
    max-width:640px;
margin:0 auto;padding:20px;
padding-top:70px;
padding-bottom:70px;

 height:100%;
  }

    .section_6 .s1{
            grid-column-start:1;
             grid-column-end:2;
             grid-row-start:1;
             grid-row-end:2;
              padding:15px;
         }

         .section_6 .ico6  {
            display: flex;
            align-items: center;
            justify-content: center; 
         
            border-radius: 50%;
            font-size: 34px;
         }

        
         .section_6 .text6{
            width:100%;
        
            padding:5px;
            font-size: 14px;
            font-family: 'Montserrat' ;
         }                       

         .section_6 .s2{
                 grid-column-start:2;
                 grid-column-end:3;
                 grid-row-start:1;
                 grid-row-end:2;
                 padding-top:100px;
                }
                .section_6 .s3{
                    grid-column-start:1;
                    grid-column-end:2;
                    grid-row-start:2;
                    grid-row-end:3;
                    padding:15px;
                   }

                   .section_6 .s4{
                    grid-column-start:2;
                    grid-column-end:3;
                    grid-row-start:2;
                    grid-row-end:3;
                    
                    padding-top:100px;
                   }

                   .section_6 a{
                    transition: 2s;
                    text-decoration: none;
                  
                   }
                   .section_6 a:hover{
                   transition: 2s;
                  
                   }}

                   @media (max-width: 486px) {    

                    .section_6{
                        display: grid;
                        grid-template-columns: 1fr ; 
                        grid-template-rows: 1fr 1fr 1fr 1fr ; 
                        max-width:95%;
                    margin:0 auto;
                    padding:15px;
                                  
                     height:100%;
                      }

                        

                             .section_6 .ico6  {
                                display: flex;
                                align-items: center;
                                justify-content: center; 
                                 height:50px;
                                 width:50px;
                        
                                border-radius: 50%;
                                padding:0px;
                                
                                font-size: 20px;
                             }

                             .section_6 .title6{
                                display: flex;
                               align-items: center; 
                                width:100%;                           
                                 font-weight: bolder;
                                 text-transform: uppercase;
                                 font-size: 16px;
                                 padding:5px;
                                 font-family: 'Oswald' ;
                                 padding-top:20px;
                            
                             }
                             .section_6 .text6{
                                width:100%;
                            
                                padding:5px;
                                font-size: 14px;
                                font-family: 'Montserrat' ;
                             }                       
                             .section_6 .s1{
                              grid-column-start:1;
                               grid-column-end:2;
                               grid-row-start:1;
                               grid-row-end:2;
                                padding:5px;
                                padding-top:25px;
                                 padding-bottom:25px;
                           }
                             .section_6 .s2{
                                     grid-column-start:1;
                                     grid-column-end:2;
                                     grid-row-start:2;
                                     grid-row-end:3; padding:5px;
                                     
                                    }
                                    .section_6 .s3{
                                        grid-column-start:1;
                                        grid-column-end:2;
                                        grid-row-start:3;
                                        grid-row-end:4;
                                        padding:5px;  
                                       }

                                       .section_6 .s4{
                                        grid-column-start:1;
                                        grid-column-end:2;   
                                        grid-row-start:4;
                                        grid-row-end:5;
                                        padding:5px;
                                       }

                                       .section_6 a{
                                        transition: 2s;
                                        text-decoration: none;
                                     }
                                       .section_6 a:hover{
                                       transition: 2s;
                                    
                                       }
                   }


