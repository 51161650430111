.dmt{
  height:450px;
 padding-top:60px;
 background-color: #f7f9fc;
 /*background: linear-gradient(to bottom,#f9fbf7, #fbfbfb);*/
 display: flex;
 
flex-direction: row;
   
  color:#111111;
 
-webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
                                    
}
.dmt_img{
  width:30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  }

.dmt_text{
width:70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
   
}
 

  .cl1{
   
   letter-spacing: 0px;
  font-size: 20px;
  text-transform: uppercase; 
 font-family: 'Oswald';
 font-family: 'Roboto';
  transition-duration: 0.5s;
   

}

 .cl2{
   
  word-wrap: break-word;
  width:390px;
  text-align: center;
  font-size: 36px;
  font-family: 'Oswald';
   line-height: 40px;
  letter-spacing: 0px;
 font-weight: 600;
}

.cl3{
  font-family: 'Roboto';
   letter-spacing: 0px;
  font-size: 16px;
  
  
}


@media (max-width: 990px) {
.dmt{
   
  height:350px;
  padding-top:70px;
 /*  background: #2d2d2dd8;*/
 -webkit-touch-callout: none; /* iOS Safari */
       -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
           -ms-user-select: none; /* Internet Explorer/Edge */
               user-select: none; /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
 }
 
}




@media (max-width: 640px) {
   
  .dmt{
    height:300px;
  
  
  }
  .dmt_img{
    width:0%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    }
  
  .dmt_text{
  width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
     
  }
   
  
    .cl1{
     
     letter-spacing: 0px;
    font-size: 14px;
    text-transform: uppercase; 
   font-family: 'Oswald';
   font-family: 'Roboto';
    transition-duration: 0.5s;
     
  
  }
  
   .cl2{
     
    word-wrap: break-word;
    width:350px;
    text-align: center;
    font-size: 28px;
    font-family: 'Oswald';
     line-height: 27px;
    letter-spacing: 0px;
   font-weight: 600;
  }
  
  .cl3{
    font-family: 'Roboto';
     letter-spacing: 0px;
    font-size: 14px;
    
    
  }
  

}