.samt{
height:350px;
max-width:1170px;
background-color: white;
 padding-top:30px;
margin:0 auto;
}

.samt_block{
    display: flex;
    flex-direction: row;
    width:100%;
    height:100%;
    justify-content: space-around;
    align-items: center;
    color:#1d2636;
    padding:15px;
}

.block_1{
width:24%;
height:100%;
 
 display: flex;
 justify-content: flex-start;
 align-items: center;
 flex-direction: column;
 border:2px solid #1d2636;
 box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
 border-radius: 7px;
}

.block_2{
    width:24%;
height:100%;
border:2px solid #1d2636;box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
display: flex;
justify-content: flex-start;
align-items: center; flex-direction: column;  border-radius: 7px;
}
.block_3{
    width:24%;border-radius: 7px;box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
height:100%;
border:2px solid #1d2636;
display: flex;
justify-content: flex-start;
align-items: center; flex-direction: column;  
}

.block_4{
    width:24%;border-radius: 7px;box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
height:100%;
border:2px solid #1d2636;
display: flex;
justify-content: flex-start;
align-items: center; flex-direction: column;  
}


.icon_block{
    font-size:42px;
     
    height:90px;
    width:100px;
    text-align: center;
    width:100px;
    
    
    color:white;
    margin-top:50px;
}
.text_block{
    font-size: 18px;
    font-weight: 600;
    margin-top:50px;
}

.text_block a{
    text-decoration: none;
    font-family: 'Oswald';
    color:#1d2636;
   
    font-weight:500
}


@media (max-width: 990px) {
    .samt{
        height:300px;
        max-width:990px;
    
         padding-top:30px;
        margin:0 auto;
        }
        
        
}

@media (max-width: 870px) {
    .samt{
        height:600px;
        
        max-width:600px;
        
         padding:10px;
        margin:0 auto;
        }
        
        .samt_block{
            display: flex;
            flex-direction: none;
            flex-wrap: wrap;
            width:100%;
            height:100%;
            justify-content: space-around;
            align-items: center;
            color:#1d2636;
            padding:15px;
        }
        
        .block_1{
            width:45%;
            height:45%;
         display: flex;
         justify-content: flex-start;
         align-items: center;
         flex-direction: column;
         border:3px solid #1d2636;
         margin:5px;
        }
        
        .block_2{
            width:45%;
            height:45%;
        border:3px solid #1d2636;
        display: flex;
        justify-content: flex-start;
        align-items: center; flex-direction: column;   margin:5px;
        }
        .block_3{
            width:45%;
            height:45%;
        border:3px solid #1d2636;
        display: flex;
        justify-content: flex-start;
        align-items: center; flex-direction: column;  margin:5px; 
        }
        
        .block_4{
            width:45%;
            height:45%;
        border:3px solid #1d2636;
        display: flex;
        justify-content: flex-start;
        align-items: center; flex-direction: column;   margin:5px;
        }
        
        
        .icon_block{
            font-size:42px;
             
            height:90px;
            width:100px;
            text-align: center;
            width:100px;
            
            
            color:white;
            margin-top:50px;
        }
        .text_block{
            font-size: 18px;
            font-weight: 600;
            margin-top:30px;
        }
        
        .text_block a{
            text-decoration: none;
            font-family: 'Oswald';
            color:#1d2636;
            font-weight:500
        }
        
        
        
}


@media (max-width: 580px) {
    .samt{
        height:500px;
        
        width:100%;
        
         padding:5px;
        margin:0 auto;
        }
        
        .samt_block{
            display: flex;
            flex-direction: none;
            flex-wrap: wrap;
            width:100%;
            height:100%;
            justify-content: space-around;
            align-items: center;
          
            padding:15px;
        }
        
        .block_1{
            width:45%;
            height:45%;
         display: flex;
         justify-content: flex-start;
         align-items: center;
         flex-direction: column;
        
         margin:5px;
        }
        
        .block_2{
            width:45%;
            height:45%;
      
        display: flex;
        justify-content: flex-start;
        align-items: center; flex-direction: column;   margin:5px;
        }
        .block_3{
            width:45%;
            height:45%;
       
        display: flex;
        justify-content: flex-start;
        align-items: center; flex-direction: column;  margin:5px; 
        }
        
        .block_4{
            width:45%;
            height:45%;
       
        display: flex;
        justify-content: flex-start;
        align-items: center; flex-direction: column;   margin:5px;
        }
        
        
        .icon_block{
            font-size:42px;
             
            height:80px;
            width:90px;
            text-align: center;
            width:100px;
            
            
            color:white;
            margin-top:30px;
        }
        .text_block{
            font-size: 18px;
            font-weight: 600;
            margin-top:30px;
        }
        
        .text_block a{
            text-decoration: none;
            font-family: 'Oswald';
          
            font-weight:500
        }
        
        
        
}


@media (max-width: 460px) {
    .samt{
        height:1000px;
        
        width:100%;
        
         padding:5px;
        margin:0 auto;
        }
        
        .samt_block{
            display: flex;
            
            margin:0 auto;
            width:70%;
            height:100%;
            justify-content: center;
            align-items: center;
          
            padding:15px;
        }
        
        .block_1{
            width:100%;
            height:24%;
         display: flex;
         justify-content: flex-start;
         align-items: center;
         flex-direction: column;
      
         margin:5px;
        }
        
        .block_2{
            width:100%;
            height:24%;
    
        display: flex;
        justify-content: flex-start;
        align-items: center; flex-direction: column;   margin:5px;
        }
        .block_3{
            width:100%;
            height:24%;
      
        display: flex;
        justify-content: flex-start;
        align-items: center; flex-direction: column;  margin:5px; 
        }
        
        .block_4{
            width:100%;
            height:24%;
       
        display: flex;
        justify-content: flex-start;
        align-items: center; flex-direction: column;   margin:5px;
        }
        
        
        .icon_block{
            font-size:42px;
             
            height:80px;
            width:90px;
            text-align: center;
            width:100px;
            
            
            color:white;
            margin-top:30px;
        }
        .text_block{
            font-size: 18px;
            font-weight: 600;
            margin-top:30px;
        }
        
        .text_block a{
            text-decoration: none;
            font-family: 'Oswald';
          
            font-weight:500
        }
        
        
        
}