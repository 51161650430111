.modalmBackground{
    width:100%;
height:100%;
position: fixed;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(152, 161, 142, 0.911);
z-index:9999999;
}

.modalmBackground .modalmContainer{
    height:480px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding:25px;
     width:500px;
}

 


.titlemCloseBtn{
    display: flex;
    justify-content:flex-end; /* горизонт */
     
    align-items:center; /* вертикал */

}


.titlemCloseBtn button{
background-color: transparent;
border:none;
font-size: 24px;
cursor: pointer;
padding:0px;
margin-top:-7px;
display: flex;
justify-content:right; /* горизонт */
 
width:40%;
 float:right;
}

 .container.ContMail{
    
    width:100%;
    padding:10px;
 }

 .titlemCloseBtn h1{
    font-size: 18px;
    font-family: 'Oswald';
    text-transform: uppercase;
   
    padding:0px;
    margin:0;
 }
 .container.ContMail Form{
     font-size: 16px;
    width:100%;
 }

 .container.ContMail Form button{
background-color: rgb(250, 134, 25);
padding:8px 16px;
font-family: 'Oswald';
text-decoration: none;
text-transform: uppercase;
font-size: 14px;
color:#4867d6;
border-radius: 5px;
border:1px solid #4867d6;
background: rgba(0, 0, 0, 0.027);
margin-top:0px;
float:right;
transition: 1s;
 }

 .container.ContMail Form button:hover{
    color:white;
    background-color: #4867d6;
    transition: 1s;
 }


 
 

@media (max-width: 640px) {
   .container Form button{
   background-color: rgb(250, 134, 25);
   width:100%;
   padding:6px 14px;
   font-family: 'Montserrat';
   text-decoration: none;
   text-transform:none;
   font-size: 16px;
   color:#4867d6;
   border-radius: 5px;
   border:1px solid #4867d6;
   background: rgba(0, 0, 0, 0.027);
   margin-top:20px;
   float:right;
   transition: 1s;
   }


   .modalmBackground .modalmContainer{
      height:500px;
      width:90%;
      margin:0 auto;
      border-radius: 12px;
      background-color: white;
      box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
      display: flex;
      flex-direction: column;
      padding:25px;
       
  }
  }
 
  