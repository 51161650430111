

.LoginBackground{
    width:100%;
height:100%;
position: fixed;
display: flex;
justify-content: center;
align-items: center;
 
 
z-index:9999999;
font-family: 'Montserrat';
font-size: 12px;
 
}


.LoginContainer{
    width:400px;
    height:auto;
    border-radius: 5px;
    background-color: transparent; 
     box-shadow: rgba(0,0,0,0.35) 0px 5px 15px; 
    
    display: flex;
    flex-direction: column;
    padding:20px;
     
}

 

.titleCloseBtnLogin{
    display: flex;
    justify-content: space-between;
    color:#3d3d3d;
     align-items: center;
     padding-right:20px;
    
}

 
.bx{
    background-color: transparent;
    border:none;
    font-size: 30px;
 }

 
  .bgLogin{
    height:auto;
    background-color: transparent;
    width:100%;
    padding-top:10px;
    display: flex;
    justify-content: center;
    color:#3d3d3d;
   flex-direction: column;
 margin:0px;
 overflow: none;
  
}


.titleCloseBtnLogin ul{
     list-style: none;
     
     width:100%;
     text-align: center;
     
}


.titleCloseBtnLogin h5{
    width:100%;
    text-align: center;
    color:#3d3d3d;
    padding-top:5px;
    font-size: 16px;
}

.formLogin{
    width:90%;
    height:220px;
    color:#3d3d3d;
  padding-right:0px; 
   
}

.formLogin label{
    font-weight: 400;
    color:#3d3d3d;
    
}
.formLogin input{
    background: transparent;
    height:35px;
    border:none;
    outline: none;
    color:#3d3d3d;
    border-bottom:1px solid grey;
}
.formLogin button{
   
    margin-top:30px;
}

 


.formLogin input:focus ~ label, .formSignUp input:focus ~ :valid{
top:-5;
}

.formLogin input:focus{
    background: transparent;
    border:none;
}
.formLogin input::placeholder{
     
    opacity: 1;
    font-size: 13px;
}

.formLogin input::-ms-input-placeholder{
    color:rgb(116, 113, 113);   opacity: 1;
}

 
.formLogin .form-control {
    color:#3d3d3d;font-size: 14px;
    
}
 
 

 
 
@media (max-width: 768px) {


    .LoginBackground{
        width:100%;
    height:100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index:9999999;
    font-family: 'Montserrat';
    font-size: 13px;
     padding-top:80px;
    }
    
    
    .LoginContainer{
        width:400px;
        height:auto;
        border-radius: 5px;
        background-color: transparent; 
       /* box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;*/
       border:2px white solid;
        display: flex;
        flex-direction: column;
        padding:20px;
         
    }

}



 
 

    @media (max-width: 480px) {
        .LoginContainer{
padding:15px;
    }

    .LoginBackground{
        width:100%;
    height:100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  
    z-index:9999999;
    font-family: 'Montserrat';
    font-size: 13px;
     padding :15px;
    }

}


 

