
.mav{
   width:100%;
   margin:0 auto;
   
   padding:0;
   
}

section {
    padding: 0x;
    max-width: 1170px;
    margin: 0px auto;
    height:auto;
    border:2px transparent solid;
   
  }

  section h1{
    width:100%;
    display:flex;
     font-size: 18px;
     font-family: Oswald;;
     text-transform:uppercase;
     color:#3d3d3d;
    justify-content: center;
     margin:80px auto;
     font-weight: 600;
  }

.mavod{
  width:100%;
  height:auto;
  margin-top:0px;
  
}

  .mavod_grid{
    width:90%;
    margin-left:5%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
 
  }
 .book{
    width:100%;
   
      height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    
      border-bottom:1px rgb(223, 223, 223) solid;
     padding:15px;
     padding-bottom: 50px;
     padding-top: 50px;
 }

 .nomer{
    font-weight: 700;
    font-size: 18px;
    position: absolute;
    margin-left:-50px;
    font-family: 'Montserrat';
    color:#2d6b9f;
 }
 .imgbook{
width:220px;
height:300px;
 
box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
 }
 .aboutbook{
    width:auto;
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left:15px;
 
 }

 

 .titlebook{
    color:#3d3d3d;
    font-weight: 600;
    font-size: 18px;
    text-transform:none;
    font-family: 'Oswald';
    
    padding:10px;
    padding-top:5px;
     
 }
 .autorbook{
    color:#f3a617;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Montserrat'; 
    padding:10px;
    
 }
 .autorbook a.muallif{
    font-weight: 500;
    font-size: 14px;
    font-family: 'Montserrat'; 
    color:#3d3d3d;
    text-decoration: none;
 }

 .statusbook{
    font-weight: 600;
    font-size: 14px;
    font-family: 'Montserrat'; 
    color:#3d3d3d;
    text-decoration: none;
    padding:10px;
    padding-top:0px;
 }
 .commentbook{
    color:#3d3d3d;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Montserrat'; 
    padding:10px;
    padding-top:0px;
  text-align: justify;
 }

.yearbook{
    color:#828282;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Montserrat';
     padding-left:10px;
     padding-top:0px;
 }


 @media (max-width: 1024px) {

    section {
      padding: 0x;
      max-width: 1024px;
      margin: 0px auto;
      height:auto;
       
        
    }
  
   
.mavod{
    width:100%;
    height:auto;
    margin-top:30px;
   
       
  }
  
    .mavod_grid{
      width:96%;
      margin-left:4%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center; 
   
    }
   .book{
      width:100%;
       
        height: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      
        border-bottom:1px rgb(223, 223, 223) solid;
       padding:5px;
       padding-bottom: 30px;
       padding-top: 30px;
   }
   .nomer{
    font-weight: 700;
    font-size: 18px;
    position: absolute;
    margin-left:-25px;
    font-family: 'Montserrat';
    color:#2d6b9f;
 }
  

 .titlebook{
    color:#3d3d3d;
    font-weight: 600;
    font-size: 16px;
    text-transform:none;
    font-family: 'Oswald';
    
    padding:6px;
    padding-top:5px;
     
 }
 .autorbook{
    color:#f3a617;
    font-weight: 500;
    font-size: 13px;
    font-family: 'Montserrat'; 
    padding:6px;
    
 }
 .autorbook a.muallif{
    font-weight: 500;
    font-size: 13px;
    font-family: 'Montserrat'; 
    color:#3d3d3d;
    text-decoration: none;
 }

 .statusbook{
    font-weight: 600;
    font-size: 13px;
    font-family: 'Montserrat'; 
    color:#3d3d3d;
    text-decoration: none;
    padding:6px;
    padding-top:0px;
 }
 .commentbook{
    color:#3d3d3d;
    font-weight: 400;
    font-size: 13px;
    font-family: 'Montserrat'; 
    padding:6px;
    padding-top:0px;
  text-align: justify;
 }

.yearbook{
    color:#828282;
    font-weight: 400;
    font-size: 13px;
    font-family: 'Montserrat';
     padding-left:6px;
     padding-top:0px;
 }


    }



    @media (max-width: 990px) {

      section h1{
         width:100%;
         display:flex;
          font-size: 18px;
          font-family: Oswald;;
          text-transform:uppercase;
          color:#3d3d3d;
         justify-content: center;
          margin:10px auto;
          font-weight: 600;
       }




      section {
        padding: 0px;
        max-width: 990px;
        margin: 0px auto;
        height:auto;
         padding-top:90px
          
      }

   }



    @media (max-width: 768px) {

        section {
          padding: 0px;
          max-width: 768px;
          margin: 0px auto;
          height:auto;
          padding-top:90px
            
        }
        
        
         
      .mavod{
          width:100%;
          height:auto;
          margin-top:30px;
         
             
        }
        
          .mavod_grid{
            width:98%;
            margin-left:1%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center; 
         
          }
         .book{
            width:100%;
             
              height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            
              border-bottom:1px rgb(223, 223, 223) solid;
             padding:5px;
             padding-bottom: 30px;
             padding-top: 30px;
         }

         .aboutbook{
            width:auto;
           
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding:15px;
         
         }
         .imgbook{
            width:220px;
            height:300px;
             margin:0 auto;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            
            
             }
         .nomer{
          font-weight: 700;
          font-size: 18px;
          position: absolute;
          margin-left:30px;
          font-family: 'Montserrat';
          color:#2d6b9f;
       }
        
      
       .titlebook{
          color:#3d3d3d;
          font-weight: 600;
          font-size: 16px;
          text-transform:none;
          font-family: 'Oswald';
          
          padding:6px;
          padding-top:5px;
           
       }
       .autorbook{
          color:#f3a617;
          font-weight: 500;
          font-size: 14px;
          font-family: 'Montserrat'; 
          padding:6px;
          
       }
       .autorbook a.muallif{
          font-weight: 500;
          font-size: 14px;
          font-family: 'Montserrat'; 
          color:#3d3d3d;
          text-decoration: none;
       }
      
       .statusbook{
          font-weight: 600;
          font-size: 14px;
          font-family: 'Montserrat'; 
          color:#3d3d3d;
          text-decoration: none;
          padding:6px;
          padding-top:0px;
       }
       .commentbook{
          color:#3d3d3d;
          font-weight: 400;
          font-size: 14px;
          font-family: 'Montserrat'; 
          padding:6px;
          padding-top:0px;
        text-align: justify;
       }
      
      .yearbook{
          color:#828282;
          font-weight: 400;
          font-size: 14px;
          font-family: 'Montserrat';
           padding-left:6px;
           padding-top:0px;
       }
       
      
        }









    @media (max-width: 480px) {

      section {
       
        max-width: 480px;
        margin: 0px auto;
        height:auto;
         
          
      }
      
      
      
       
    .mavod{
        width:100%;
        height:auto;
        margin-top:10px;
       
           
      }
      
        .mavod_grid{
          width:98%;
          margin-left:1%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center; 
       
        }
       .book{
          width:100%;
         
            height: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          
            border-bottom:1px rgb(223, 223, 223) solid;
           padding:5px;
           padding-bottom: 30px;
           padding-top: 30px;
       }

       .aboutbook{
          width:auto;
        
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding:5px;
       
       }
       .imgbook{
          width:200px;
          height:280px;
           margin:0 auto;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
          
          
           }
       .nomer{
        font-weight: 700;
        font-size: 16px;
        position: absolute;
        margin-left:30px;
        font-family: 'Montserrat';
        color:#3d3d3d;
     }
      
    
     .titlebook{
        color:#3d3d3d;
        font-weight: 600;
        font-size: 16px;
        text-transform:none;
        font-family: 'Oswald';
        
        padding:6px;
        padding-top:5px;
         
     }
     .autorbook{
        color:#f3a617;
        font-weight: 500;
        font-size: 14px;
        font-family: 'Montserrat'; 
        padding:6px;
        
     }
     .autorbook a.muallif{
        font-weight: 500;
        font-size: 14px;
        font-family: 'Montserrat'; 
        color:#3d3d3d;
        text-decoration: none;
     }
    
     .statusbook{
        font-weight: 600;
        font-size: 14px;
        font-family: 'Montserrat'; 
        color:#3d3d3d;
        text-decoration: none;
        padding:6px;
        padding-top:0px;
     }
     .commentbook{
        color:#3d3d3d;
        font-weight: 400;
        font-size: 14px;
        font-family: 'Montserrat'; 
        padding:6px;
        padding-top:0px;
      text-align: justify;
     }
    
    .yearbook{
        color:#828282;
        font-weight: 400;
        font-size: 14px;
        font-family: 'Montserrat';
         padding-left:6px;
         padding-top:0px;
     }
     
    
      }