.tiobe{
    height:auto;

          user-select: none;             
    
  
 
      
    
}

.b_tiobe{
  width:100%;
 background-color:   #4867d6f6;
  padding:50px;
height:auto;
 
}

.d_tiobe {
    max-width:1170px;
   
    margin:0 auto;
    height:100%;
    display: flex;
    flex-direction: row;
    padding:20px;
}


 

.l_tiobe{
    width:100%;
    padding:10px;
    display: flex;
    flex-direction: column;
}

.d_tiobe .d_1 h1{
    color:white;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 600;
}

.d_tiobe .d_2 p{
    color:white;
    font-size: 14px;
    font-family: 'Montserrat';
    width:100%;
}

.d_tiobe .d_1 a{
    color: rgb(255, 135, 7);
    font-weight: 600;
}

.d_tiobe .d_3 a{
    color: rgb(255, 135, 7);
}

.d_tiobe .d_3 p{
    color:white;
    font-size: 14px;
    font-family: 'Montserrat';width:100%;
}
.d_tiobe .d_4 h1{
    color: rgb(255, 135, 7);
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 700;
}
.d_tiobe .d_4 p{
    color:white;
    font-size: 14px;
    font-family: 'Montserrat';
    width:100%;
}

.d_tiobe .d_4 .d_block   {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: start;
   padding:10px;
   width:60%;
    
  
}

 

.d_tiobe .d_4 .d_block ul li {
    color:white;
    font-size: 14px;
    font-family: 'Montserrat';
}
.d_tiobe .d_4 .d_block ul{
     padding-left:30px;
    width:45%;
    padding:5px;
     
   
}

.d_tiobe .d_4 .d_block .ul_img{
    text-align: center;
    width:400px;
    padding:10px;
    
    margin:0px;
    height:350px;
}
.d_tiobe  .ul_img h1{
    color: rgb(255, 135, 7);
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 700;
}
 
@media (max-width: 990px) {


    .b_tiobe{
        width:100%;
      
        padding:20px;
      height:auto;
       
      }
      
      .d_tiobe {
          max-width:990px;
         
          margin:0 auto;
          height:100%;
          display: flex;
          flex-direction: row;
          padding:20px;
      }
      
      
       
      
      .l_tiobe{
          width:100%;
          padding:10px;
          display: flex;
          flex-direction: column;
      }
      
      .d_tiobe .d_1 h1{
          color:white;
          font-size: 16px;
          font-family: 'Montserrat';
          font-weight: 600;
      }
      
      .d_tiobe .d_2 p{
          color:white;
          font-size: 14px;
          font-family: 'Montserrat';
          width:100%;
      }
      
      .d_tiobe .d_1 a{
          color: rgb(255, 135, 7);
          font-weight: 600;
      }
      
      .d_tiobe .d_3 a{
          color: rgb(255, 135, 7);
      }
      
      .d_tiobe .d_3 p{
          color:white;
          font-size: 14px;
          font-family: 'Montserrat';width:100%;
      }
      .d_tiobe .d_4 h1{
          color: rgb(255, 135, 7);
          font-size: 14px;
          font-family: 'Montserrat';
          font-weight: 700;
      }
      .d_tiobe .d_4 p{
          color:white;
          font-size: 14px;
          font-family: 'Montserrat';
          width:100%;
      }
      
      .d_tiobe .d_4 .d_block   {
         display: flex;
         flex-direction: row;
         justify-content: left;
         align-items: start;
         padding:10px;
         width:100%;
          
        
      }
      
       .d_tiobe .d_4 .d_block ul{
           padding:10px;
          width:250px;
         
           
         
      }
      
      .d_tiobe .d_4 .d_block ul li {
          color:white;
          font-size: 14px;
          font-family: 'Montserrat';
      }
      
      
      .d_tiobe .d_4 .d_block .ul_img{
          text-align: center;
          width:300px;
          padding:10px;
          
          margin:0px;
          height:300px;
      }
      .d_tiobe  .ul_img h1{
          color: rgb(255, 135, 7);
          font-size: 14px;
          font-family: 'Montserrat';
          font-weight: 700;
      }

}



@media (max-width: 685px) {


    .b_tiobe{
        width:100%;
       
        padding:20px;
      height:auto;
       
      }
      
      .d_tiobe {
          max-width:680px;
         
          margin:0 auto;
          height:100%;
          display: flex;
          flex-direction: row;
          padding:10px;
      }
      
      
       
      
      .l_tiobe{
          width:100%;
          padding:10px;
          display: flex;
          flex-direction: column;
      }
      
      
      
      
       .d_tiobe .d_4 .d_block ul{
           padding:10px;
          width:180px;
        
           
         
      }
      
      .d_tiobe .d_4 .d_block ul li {
          color:white;
          font-size: 13px;
          font-family: 'Montserrat';
      }
      
      
      .d_tiobe .d_4 .d_block .ul_img{
          text-align: center;
          width:330px;
          padding:10px;
          
          margin:0px;
          height:300px;
      }
      .d_tiobe  .ul_img h1{
          color: rgb(255, 135, 7);
          font-size: 12px;
          font-family: 'Montserrat';
          font-weight: 700;
      }

}




@media (max-width: 550px) {


    .b_tiobe{
        width:100%;
        
        padding:10px;
      height:auto;
       
      }
      
      .d_tiobe {
          width:100%;
         
          
          height:100%;
          display: flex;
          flex-direction: column;
          padding:10px;
      }
      
      
       
      
      .l_tiobe{
          width:100%;
          padding:10px;
          display: flex;
          flex-direction: column;
         
      }
      
      .d_tiobe .d_4 .d_block {
        flex-direction: column;
      }
    
      
       .d_tiobe .d_4 .d_block ul{
           padding:10px;
          width:180px;
         
           
         
      }
      
      .d_tiobe .d_4 .d_block ul li {
          color:white;
          font-size: 13px;
          font-family: 'Montserrat';
      }
      
      
      .d_tiobe .d_4 .d_block .ul_img{
          text-align: center;
          width:70%;
          padding:5px;
         
          margin:0px;
          height:300px;
      }


      .d_tiobe  .ul_img h1{
          color: rgb(255, 135, 7);
          font-size: 12px;
          font-family: 'Montserrat';
          font-weight: 700;
      }

}



@media (max-width:480px){
    .d_tiobe .d_4 .d_block .ul_img{
        text-align: center;
        width:90%;
        padding:5px;
       
        margin:0px auto;
        height:330px;
    }
}


@media (max-width:400px){
    .d_tiobe .d_4 .d_block .ul_img{
        text-align: center;
        width:100%;
        padding:5px;
       
        margin:0px auto;
        height:330px;
    }
}