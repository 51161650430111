
 
                      
                      .modal{
 z-index:999999;
padding-top:60px;
 
                      }
                     
.modal-title{
  font-size: 16px;
}
                      .modal-body{
                       
                        width:auto;
                        display: flex;
                        justify-content: center;
                      }
                      .modal table tr td{
                        border:1px solid #3d3d3d;
                        padding:3px;
                        font-size:12px;
                      }

                      .modal table tr td.ttd{
                        border:none;
                        padding:0 0 5 5;
                        font-size:12px;
                      }

                        .item_7{   /* Маводҳо */
                            padding:30px;
                         width:100%;
                           
                         background-color: #f7f9fc;
                         
                          
                             padding:0px;
                              padding-top:20px;
                              
                          }
                           

                          

                          .item_7 .h_item7{
                            display: flex;
                            align-items: center; 
                            justify-content: center;
                                                      
                              font-weight: 600;
                              text-transform: uppercase;
                              font-size: 16px;
                              padding-top:30px;
                              font-family: 'Oswald' ;
                           
                              color:#3d3d3d
                              
                          }
                          .item_7 .section_7{
                            display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr; 
                        grid-template-rows: 1fr;
                        max-width:1170px;
                        margin:0 auto;   
                        height:350px;
                        padding-left:30px;
                        animation: 1s show ease;
                        position:relative;
                    
                          }

                          .item_7 .section_7 .line7{
                            width:50px;
                            border-bottom :5px grey solid;
                            height:20%; 
                          }

                          .item_7 .section_7 .title7{
                            width:100%;
                            font-weight: 500;
                            text-transform:none;
                            font-size: 18px;
                            margin-bottom:10px;
                            padding-bottom:0px;
                            font-family: 'Oswald' ;
                             cursor: pointer;
                         padding-top:10px;
                         color:#3d3d3d;
                            height:15%; 
                          }

                         



                          .item_7 .section_7 .text7{
                            width:100%;
                             
                            height:60%;
                            font-family: 'Montserrat' ;
                            font-size: 14px;
                            padding-top:0px;
                          }

                          .item_7 .section_7 .s1{
                            padding:10px;
                            grid-column-start:1;
                            grid-column-end:2; 
                            grid-row-start: 1;
                            grid-row-end: 2;
                          }
                          .item_7 .section_7 .s2{
                            padding:10px;
                            grid-column-start:2;
                            grid-column-end:3; 
                            grid-row-start: 1;
                            grid-row-end: 2;
                          
                          }
                          .item_7 .section_7 .s3{
                            padding:10px;
                            grid-column-start:3;
                            grid-column-end:4; 
                            grid-row-start: 1;
                            grid-row-end: 2;
                            
                          }
                          .item_7 .section_7 .s4{
                            padding:10px;
                            grid-column-start:4;
                            grid-column-end:5; 
                            grid-row-start: 1;
                            grid-row-end: 2;
                            
                          }
                         

                          .item_7 .aleft{
                            text-decoration: none;
                            color:#3d3d3d
                          }
                          
                      
/******************************   1024  ******************************************************/
@media (max-width: 1024px) { 
                      
                      
                            
    .item_7{
         
        grid-column-start:1;
        grid-column-end:2;  
        grid-row-start: 7;
        grid-row-end: 8;
                     
         padding:10px;
      }

      .item_7 .section_7{
        display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr;
    max-width:1024px;
    margin:0 auto;   
    height:350px;
    padding:15px;
     
    position:relative;
      }

      .item_7 .section_7 .line7{
        width:90px;
        border-bottom :3px #c5c4c4 solid;
        height:20%; 
      }

      .item_7 .section_7 .title7{
        width:100%;
        font-weight: bolder;
        text-transform:none;
        font-size: 16px;
        padding-bottom:0px;
        font-family: 'Oswald' ;
         cursor: pointer;
     padding-top:10px;

        height:20%; 
      }
       
      .item_7 .section_7 .text7{
        width:100%;
        height:60%;
        font-family: 'Montserrat' ;
        font-size: 14px;
        padding-top:0px;
      }

      .item_7 .section_7 .s1{
        padding:10px;
        grid-column-start:1;
        grid-column-end:2; 
        grid-row-start: 1;
        grid-row-end: 2;
      }
      .item_7 .section_7 .s2{
        padding:10px;
        grid-column-start:2;
        grid-column-end:3; 
        grid-row-start: 1;
        grid-row-end: 2;
      
      }
      .item_7 .section_7 .s3{
        padding:10px;
        grid-column-start:3;
        grid-column-end:4; 
        grid-row-start: 1;
        grid-row-end: 2;
        
      }
      .item_7 .section_7 .s4{
        padding:10px;
        grid-column-start:4;
        grid-column-end:5; 
        grid-row-start: 1;
        grid-row-end: 2;
        
      }
         
      .item_7 .aleft{
        text-decoration: none;
      
      }
      

      


}
/******************************   990  ******************************************************/ 




@media (max-width: 870px) {      

        .item_7{                    /*    Маводҳо       */
            padding:10px;
            grid-column-start:1;
            grid-column-end:2;  
            grid-row-start: 7;
            grid-row-end: 8;
           height:auto;
          }

             
          .item_7 .section_7{
            display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr; 
        grid-template-rows: 1fr;
        max-width:870px;
        margin:0 auto;   
        height:350px;
        position:relative;
          }

          .item_7 .section_7 .line7{
           
            height:20%; 
          }

          .item_7 .section_7 .title7{
            width:100%;
            font-weight: bolder;
            text-transform:none;
            font-size: 17px;
            padding-bottom:0px;
            font-family: 'Oswald' ;
             cursor: pointer;
         padding-top:10px;
           
            height:25%; 
          }

         



          .item_7 .section_7 .text7{
            width:100%;
             
            height:55%;
            font-family: 'Montserrat' ;
            font-size: 14px;
            padding-top:0px;
          }

          .item_7 .section_7 .s1{
            padding:10px;
            grid-column-start:1;
            grid-column-end:2; 
            grid-row-start: 1;
            grid-row-end: 2;
          }
          .item_7 .section_7 .s2{
            padding:10px;
            grid-column-start:2;
            grid-column-end:3; 
            grid-row-start: 1;
            grid-row-end: 2;
          
          }
          .item_7 .section_7 .s3{
            padding:10px;
            grid-column-start:3;
            grid-column-end:4; 
            grid-row-start: 1;
            grid-row-end: 2;
            
          }
          .item_7 .section_7 .s4{
            padding:10px;
            grid-column-start:4;
            grid-column-end:5; 
            grid-row-start: 1;
            grid-row-end: 2;
            
          }
         

          .item_7 .aleft{
            text-decoration: none;
       
          }
          
           


}        




/******************************    768  ******************************************************/


@media (max-width: 768px) { 
        .item_7{    /*Маводҳо */
            padding:20px 10px 30px 10px;
            grid-column-start:1;
            grid-column-end:2;  
            grid-row-start: 7;
            grid-row-end: 8;
            height:auto;
            
          }

          .item_7 .h_item7{
            display: flex;
            align-items: center; 
            justify-content: center;
                                     
              font-weight: 600;
              text-transform: uppercase;
              font-size: 16px;
                
          }
          .item_7 .section_7{
            display: grid;
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr;
        max-width:768px;
        margin:0 auto;   
        height:400px;
        position:relative;
          }

          .item_7 .section_7 .line7{
                                       height:20%; 
          }

          .item_7 .section_7 .title7{
            width:100%;
            font-weight: bolder;
            text-transform:none;
            font-size: 17px;
            padding-bottom:0px;
            font-family: 'Oswald' ;
             cursor: pointer;
         padding-top:10px;
       
            height:25%; 
          }


          .item_7 .section_7 .text7{
            width:100%;
             
            height:55%;
            font-family: 'Montserrat' ;
            font-size: 14px;
            padding-top:0px;
          }

          .item_7 .section_7 .s1{
            padding:10px;
            grid-column-start:1;
            grid-column-end:2; 
            grid-row-start: 1;
            grid-row-end: 2;
          }
          .item_7 .section_7 .s2{
            padding:10px;
            grid-column-start:2;
            grid-column-end:3; 
            grid-row-start: 1;
            grid-row-end: 2;
          
          }
          .item_7 .section_7 .s3{
            padding:10px;
            grid-column-start:1;
            grid-column-end:2; 
            grid-row-start: 2;
            grid-row-end: 3;
            
          }
          .item_7 .section_7 .s4{
            padding:10px;
            grid-column-start:2;
            grid-column-end:3; 
            grid-row-start: 2;
            grid-row-end: 3;
            
          }
         

          .item_7 .aleft{
            text-decoration: none;
        
          }
          
}          


/******************************    640  ******************************************************/


@media (max-width: 640px) {  
                       .item_7 .section_7{   /* Маводҳо */
                        display: grid;
                    grid-template-columns: 1fr ; 
                    grid-template-rows: repeat(4,1fr);
                    max-width:550px;
                    margin:0 auto;   
                    height:480px;
                    position:relative;
                      }

                      .item_7 .h_item7{
                        display: flex;
                        align-items: center; 
                        justify-content: center;
                                                  
                          font-weight: bolder;
                          text-transform: uppercase;
                          font-size: 16px;
                          padding-top:10px;
                          font-family: 'Oswald' ;
                        
                      }

                      .item_7 .section_7 .line7{
                        
                        height:10px; 
                      }

                      .item_7 .section_7 .title7{
                        width:100%;
                        font-weight: bolder;
                        text-transform:none;
                        font-size: 17px;
                        padding-bottom:0px;
                        font-family: 'Oswald' ;
                         cursor: pointer;
                     padding-top:10px;
                       
                        height:40px; 
                      } 
                      .item_7 .section_7 .text7{
                        width:100%;
                         
                        height:auto;
                        font-family: 'Montserrat' ;
                        font-size: 14px;
                        padding-top:0px;
                      }

                      .item_7 .section_7 .s1{
                        padding:10px;
                        grid-column-start:1;
                        grid-column-end:2; 
                        grid-row-start: 1;
                        grid-row-end: 2;
                      }
                      .item_7 .section_7 .s2{
                        padding:10px;
                        grid-column-start:1;
                        grid-column-end:2; 
                        grid-row-start: 2;
                        grid-row-end: 3;
                      
                      }
                      .item_7 .section_7 .s3{
                        padding:10px;
                        grid-column-start:1;
                        grid-column-end:2; 
                        grid-row-start: 3;
                        grid-row-end: 4;
                        
                      }
                      .item_7 .section_7 .s4{
                        padding:10px;
                        grid-column-start:1;
                        grid-column-end:2; 
                        grid-row-start: 4;
                        grid-row-end: 5;
                        
                      }
                     

                      .item_7 .aleft{
                        text-decoration: none;
                        
                      }

          
         
          
        }
          

@media (max-width: 550px) { 
.item_7 .section_7{   /* Маводҳо */
display: grid; 
grid-template-columns: 1fr ; 
grid-template-rows: repeat(4,1fr);
max-width:500px;
margin:0 auto;   
height:500px;
position:relative;
}

.item_7 .h_item7{
display: flex;
align-items: center; 
justify-content: center;
          
font-weight: bolder;
text-transform: uppercase;
font-size: 16px;
padding-top:10px;
font-family: 'Oswald' ;

}

.item_7 .section_7 .line7{

height:10px; 
}

.item_7 .section_7 .title7{
width:100%;
font-weight: bolder;
text-transform:none;
font-size: 17px;
padding-bottom:0px;
font-family: 'Oswald' ;
cursor: pointer;
padding-top:10px;

height:40px; 
}

.item_7 .section_7 .text7{
width:100%;

height:auto;
font-family: 'Montserrat' ;
font-size: 14px;
padding-top:0px;
}

.item_7 .section_7 .s1{
padding:10px;
grid-column-start:1;
grid-column-end:2; 
grid-row-start: 1;
grid-row-end: 2;
}
.item_7 .section_7 .s2{
padding:10px;
grid-column-start:1;
grid-column-end:2; 
grid-row-start: 2;
grid-row-end: 3;

}
.item_7 .section_7 .s3{
padding:10px;
grid-column-start:1;
grid-column-end:2; 
grid-row-start: 3;
grid-row-end: 4;

}
.item_7 .section_7 .s4{
padding:10px;
grid-column-start:1;
grid-column-end:2; 
grid-row-start: 4;
grid-row-end: 5;

}


.item_7 .aleft{
text-decoration: none;

}


}




/******************************    480  ******************************************************/


@media (max-width: 486px) {      
                       .item_7 .section_7{   /* Маводҳо */
                        display: grid;
                    grid-template-columns: 1fr ; 
                    grid-template-rows: repeat(4,1fr);
                    max-width:450px;
                    margin:0 auto;   
                    height:550px;
                    position:relative;
                    
                      }

                      .item_7 .h_item7{
                        display: flex;
                        align-items: center; 
                        justify-content: center;
                                                  
                          font-weight: bolder;
                          text-transform: uppercase;
                          font-size: 16px;
                          padding-top:10px;
                          font-family: 'Oswald' ; 
                      }

                      .item_7 .section_7 .line7{
                       
                        height:10px; 
                      }

                      .item_7 .section_7 .title7{
                        width:100%;
                        font-weight: bolder;
                        text-transform:none;
                        font-size: 17px;
                        padding-bottom:0px;
                        font-family: 'Oswald' ;
                         cursor: pointer;
                     padding-top:10px;
                  
                        height:40px; 
                      }

                     



                      .item_7 .section_7 .text7{
                        width:100%;
                         
                        height:auto;
                        font-family: 'Montserrat' ;
                        font-size: 14px;
                        padding-top:0px;
                      }

                      .item_7 .section_7 .s1{
                        padding:10px;
                        grid-column-start:1;
                        grid-column-end:2; 
                        grid-row-start: 1;
                        grid-row-end: 2;
                      }
                      .item_7 .section_7 .s2{
                        padding:10px;
                        grid-column-start:1;
                        grid-column-end:2; 
                        grid-row-start: 2;
                        grid-row-end: 3;
                      
                      }
                      .item_7 .section_7 .s3{
                        padding:10px;
                        grid-column-start:1;
                        grid-column-end:2; 
                        grid-row-start: 3;
                        grid-row-end: 4;
                        
                      }
                      .item_7 .section_7 .s4{
                        padding:10px;
                        grid-column-start:1;
                        grid-column-end:2; 
                        grid-row-start: 4;
                        grid-row-end: 5;
                        
                      }
                     

                      .item_7 .aleft{
                        text-decoration: none;
                    
                      }

        }