 
.modalBackgroundDMT{
    background-image: url('../img/dmt.jpg'); 
    background-image: linear-gradient(to right, rgba(255,255,255, 0.7) 0 100%), url('../img/dmt.jpg');
    background-size: cover; 
        width:100%;
    height:100%;
  padding:15px;
display: flex;
align-items: center;
    
}






.modalmContainerDMT{
    height:auto; 
    max-width:450px;
    
    border-radius: 5px;
    padding:5px;
     margin:30px auto;
}

.ContDMT{
 overflow: auto;
height:auto; 
}

.titleCloseBtnDMT{
    display: flex;
    justify-content:flex-end; /* горизонт */
 
    align-items:center; /* вертикал */

}


.titleCloseBtnDMT button{
background-color: transparent;
border:none;
font-size: 24px;
cursor: pointer;
padding:0px;
padding-bottom: 4px;
margin-top:-7px;
display: flex;
align-items: center;
justify-content:center; /* горизонт */
 border:1px solid #3d3d3d;
width:50px;
 right:50px;
 position: absolute;
}







.text-DMT{
   text-transform: uppercase;
   font-family: 'Oswald';
    width:100%;
     font-size: 16px;
     color: #3d3d3d; 
     margin-top:5px;
    
}

 
.ContDMT  {
     margin:0px;
      
   
}


.ContDMT p {
    margin:0px;
     font-size: 14px;
     
    text-indent: 0px;
   
}
@media (max-width:990px){
    .modalmContainerDMT{
        height:auto; 
        max-width:450px;
        
        border-radius: 5px;
        padding:5px;
         margin:70px auto;
    }  
}


@media (max-width:768px){
    .titleCloseBtnDMT button{
        background-color: transparent;
        border:none;
        font-size: 24px;
        cursor: pointer;
        padding:0px;
        padding-bottom: 4px;
        margin-top:-7px;
        display: flex;
        align-items: center;
        justify-content:center; /* горизонт */
         border:1px solid #3d3d3d;
        width:45px;
         right:20px;
         top:20px;
         
         position: fixed;
        }


       
}