.navbar_all{
  width:100%;
    
    
 
    box-shadow: 0 5px 30px rgb(0 22 84 / 10%); 
 margin:0px;
    
  padding:0px;
 z-index: 99;
 position:relative;
 height:60px;
 -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
                                    background-color: #fff;
                                    position:fixed;
}

:target{
  scroll-margin-top: 200px;
}
.navbar_fixed{
  width:100%;
  background-color: #fff;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%); 
 margin:0px;
 
  padding:0px;
 z-index: 99;
 position:relative;
 height:60px;
  margin-top:0px;
  transition: .3 ease-in-out;
  position:fixed;
}



 

 
 









.nb{
 max-width: 1170px; 
 margin:0 auto; 
color:white; color:   #3d3d3d; 
padding:0px;
padding-right:15px;
padding-left:15px;
height:100%;
 

}

.dlogo{
    
   width:100%;
   display:flex;
justify-content:flex-start;
}
 
.menu{   /*  Обш. меню строка */
     
   width:100%;
   align-items: center;
   display:flex;
justify-content:flex-end;
  text-decoration: none;
  height:100%;
  transition: all 0.1s ease;
 
}

.menu_ul{    /*    Меню */
  height:60px;
    list-style: none;
  width:auto;
  text-decoration: none;
  margin: 0px;
  transition: all 0.1s ease;
  border-radius: 0px;
text-transform: uppercase;
  align-items: center;
  display:flex;
  flex-direction: row;
 
  
}



.menu_ul li{
  cursor: pointer;
  padding-left:15px;
  padding-right:15px;
  font-size: 16px;
  height:100%;  
  padding-top:18px;
  font-weight: 500;
  font-family: 'Oswald';
  
}

 


.menu_ul li span{
  width:auto;
   
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
 



.menu_ul li span .spico{
  font-size: 12px;
  margin-left:5px;
  background-image: url('../img/dw.png');
  background-size: contain;
  width: 12px;
  height: 12px;
 
}

.menu_ul li:hover .spico{
  background-image: url('../img/up1.png');
   
}
 




.menu_ul li ul{
   position: absolute;
    list-style: none;
    font-weight: 200;
     font-family: Montserrat; 
    width:auto;
   text-transform:none;
    margin: 0px;
    border-radius: 0px;
    margin-top:17px;
    color:   #3d3d3d; 
    background-color:white;
    text-align: left;
    box-shadow: 0px 5px 10px -3px rgb(61, 55, 54);
 padding:0px; 
 text-decoration: none; 
 z-index: 5;
  opacity: 0;
 transform: scaleY(0);
 transform-origin: 0 0;
 transition: .5s ease-in-out;
}


.menu_ul li:hover>ul{
opacity: 1;
transform: scaleY(1);
}

.menu_ul li ul li{
padding:0px;
font-weight: 500;
font-size: 12px;
margin:0px;
width:240px;
height:45px;    
padding-top:3px;
}


.menu_ul li ul li:hover{    
  transition: 0.2s;
}

.menu_ul li ul li:hover a{
  font-weight: 500;
  
  transition: 0.2s;
  cursor: pointer;
  padding-left:5px;
}
.menu_ul li ul li a{
  margin-left:5px;
  text-decoration: none;
  padding-left:0px;
 color:  #3d3d3d;   
  transition: 0.2s;
  font-size: 15px;
  font-weight: 400;
  
}
 
.liline{
  width:90%;
  margin:0 auto;
  height:100%;
  border-bottom:1px solid rgb(204, 197, 191);
  padding:7px 7px 7px 0px;
  font-weight: 100;
  
}

.liline_no{
  width:90%;
  margin:0 auto;
  height:100%;
  border:none;
  padding:7px 7px 7px 0px;
  font-weight: 100;
  
}

 

 

  .menu_box{
    display:none;
    }

  input.inhide{
    display:none;
  }


  
div.d1 {
   padding:5px;
  padding-left:25px;}
form {
  position: relative;
  width: 250px;
  margin: 0 auto;
  
  
}
.d1 {
  background: none;
 
height:100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}
.d1 input {
  width: 100%;
  height: 30px;
  padding-left: 10px;
  border: none;
  border-radius: 0px;
  outline: none;
  background: #ffffff;
  color: #c0bebe;
  font-size: 14px; border:1px #3d3d3d solid;
}
.d1 button {
  position: absolute; 
  top: 0px;
  right: -2px;
  
  border: none;
 
  background:  #3d3d3d;
  border-radius:   0 3px 3px 0 ;
  cursor: pointer;
  clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0 100%);
  border: 0px none;
  width:41px;
  height: 30px;
}

.iconssearch{
  color:rgb(255, 255, 255);
  font-size: 16px;
  margin-left:4px;
 
  }
 

/*********************                          search fr2                    ********************/



  .fr2 {
    padding:5px;
  padding-left:30px;
  height:60px;
 
  display: flex;
  align-items: center;
  }

   .fr2  form {
   position: relative;
   width: 80%;
   margin: 0;
    
   
 }

 .fr2 input {
   width: 100%;
   height: 30px;
   padding-left: 10px;
   border: none;
   border-radius: 0px;
   outline: none;
   background: #ffffff13;
   color: #c0bebe;
   font-size: 14px; border:1px #3d3d3d solid;
 }
 .fr2 button {
   position: absolute; 
   top: 0px;
   right: -2px;
   
   border: none;
  
   background:  #3d3d3d;
   border-radius:   0 3px 3px 0 ;
   cursor: pointer;
   clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0 100%);
   border: 0px none;
   width:41px;
   height: 30px;
 }
 
 .fr2 .iconssearch{
   color:rgb(255, 255, 255);
   font-size: 16px;
   margin-left:4px;
  
   }
  


























.iconsmenu{
 font-size: 14px;
 margin-right:30px;
 margin-top:-4px;
 margin-left:5px;

  color:grey; 
}

.iconsmenu2{
  font-size: 14px;
  margin-right:30px;
  margin-top:-4px;
  margin-left:10px;
 
  color: white;
 }

 

 .na{
  margin:0px; 
 padding:0px;
  
  font-size: 13px;
 padding:5px;
 display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
 
}

 

.nav{
  margin:0px; 
 padding:0px;
  
  font-size: 13px;
 padding:5px;
 display:none;
flex-direction: row;
justify-content: center;
align-items: center;
 
}

ul.userul{
  list-style: none; margin-left:40px;
    cursor: pointer;
   height:100%;
    margin-top: 17px;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 padding:0px;
  width:60px;  height:60px;
 
}

ul.userul li{
  height:100%;
 
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

ul.userul li{
  height:100%;
   
  width:100%;

}


ul.userul li.flex:hover>ul.userli{
display: block;
}

ul.userli{
  list-style: none;
  background-color: white;
 height:auto;
  width:auto;
  margin-left:-160px;
  margin-top:180px;
  position:absolute;
  padding:10px;
  display: flex;
  flex-direction: column;
  border:2px solid #3d3d3d;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
   display: none;
}
 

ul.userli::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: 72px;
  
  margin-top:-16px;
  border-width:7px;
  border-style: solid;
  border-color: transparent transparent #3d3d3d transparent;
}



ul.userli li{
  
 padding:3px;
  display: flex;
  flex-direction: row;  
  width:100%;
  justify-content: right;
}
ul.userli li img{
  margin-left:7px;
}
ul.userli li a{
   color:#8a8989;
}

ul.userli li a:hover{
  color:#3d3d3d;
}

 
ul.userli li span{
  font-size: 13px;
}


 

.sp_item{
  padding-left:40px;
  font-size: 12px;
}
.sp_item span{
  color:#fff;
}

.icons{
   color:#fff;
   
  
 
   
  
  
cursor: pointer;
 
}

 


















  
  /**********************************************  Menu Burger      *******************************************************************/

  @media (max-width: 990px) {
      




    .navbar_all{
      width:100%;
     
     margin:0px;
       /* box-shadow: 1px 3px 10px -3px rgb(14,18,31);*/
      padding:0px;
     z-index: 99999;
     position:relative;
     height:70px;
      margin-top:0px;
      box-shadow: none;
      transition: .3 ease-in-out;
      position:fixed;
      border-bottom:1px solid rgba(213, 221, 228, 0.429);
    
    }
    
    
    .navbar_fixed{
      width:100%;
      
     margin:0px;
        box-shadow: none;
      padding:0px;
     z-index: 99999; 
     position:relative;
     height:70px;
      margin-top:0px;
      transition: .3 ease-in-out;
      position:fixed;
      border-bottom:1px solid rgba(213, 221, 228, 0.429); 
    }
    























    .menu_ul{
      display: none;
    }
    .d1{
      display: none;
    }

    .dlogo{
   
      width:100%;
      display:flex;
   justify-content:flex-start;
   padding-left:50px;
   }


.navbar-toggler{
display:none;

}

 



 

.menu_box {                    /*    ---------------         MENU     -------------------   */
  display: block;
  position: fixed; 
 
  top: 0;
  left: -100%;
  width: 60%;
  height: 100%;
  visibility: hidden;
  padding: 0px;
  padding-top:130px;
  list-style: none;
  background-color: #f7f9fc;
  /* background: rgb(15, 175, 15);*/
  box-shadow: 1px 0px 6px rgba(40, 74, 105, 0.2);
  transition-duration: .25s;
  /*border-right: 1px #f2f3f4 solid;*/
  box-shadow: 0 0 2px green(0, 0, 0, 0.5);
  z-index: 99989;
  overflow:hidden;
  
}
.menu_box_child{
   
  background-color:#122947;
 padding-top:100px;
 overflow:auto;
 height:100%;  
 padding-left:10px;
}


@media (max-width: 768px) {
.menu_box {   /* menu*/
  display: block;
  position: fixed; 
  color: #fff;
  top: 0;
  left: -100%;
  width: 80%;
  height: 100%;
  visibility: hidden;
  
  padding-top:130px;
  list-style: none;
 
  /* background: rgb(15, 175, 15);*/
  box-shadow: 1px 0px 6px rgba(40, 74, 105, 0.2);
  transition-duration: .25s;
  /*border-right: 1px #f2f3f4 solid;*/
  box-shadow: 0 0 2px green(0, 0, 0, 0.5);
  z-index: 99989;
  overflow:hidden;
  
}
}

@media (max-width: 620px) {
  .menu_box {   /* menu*/
    display: block;
    position: fixed; 
    color: #fff;
    top: 0;
    left: -100%;
    width: 80%;
    height: 100%;
    visibility: hidden;
 
    padding-top:130px;
    list-style: none;
   
    /* background: rgb(15, 175, 15);*/
    box-shadow: 1px 0px 6px rgba(40, 74, 105, 0.2);
    transition-duration: .25s;
    /*border-right: 1px #f2f3f4 solid;*/
    box-shadow: 0 0 2px green(0, 0, 0, 0.5);
    z-index: 99989;
    overflow:hidden;
    
  }
  }
@media (max-width: 550px) {
  .menu_box {   /* menu*/
    display: block;
    position: fixed; 
    color: #fff;
    top: 0;
    left: -100%;
    width: 90%;
    height: 100%;
    visibility: hidden;
 
    padding-top:130px;
    list-style: none;
 
    /* background: rgb(15, 175, 15);*/
    box-shadow: 1px 0px 6px rgba(40, 74, 105, 0.2);
    transition-duration: .25s;
    /*border-right: 1px #f2f3f4 solid;*/
    box-shadow: 0 0 2px green(0, 0, 0, 0.5);
    z-index: 99989;
    overflow:hidden;
    
  }
}

@media (max-width: 480px) {

   




  .menu_box {   /* menu*/
    display: block;
    position: fixed; 
    color: #fff;
    top: 0;
    left: -100%;
    width: 95%;
    height: 100%;
    visibility: hidden;
 
    padding-top:130px;
    list-style: none;
 
    /* background: rgb(15, 175, 15);*/
    box-shadow: 1px 0px 6px rgba(40, 74, 105, 0.2);
    transition-duration: .25s;
    /*border-right: 1px #f2f3f4 solid;*/
    box-shadow: 0 0 2px green(0, 0, 0, 0.5);
    z-index: 99989;
    overflow:hidden;
    
  }
}

@media (max-width: 450px) {
  .menu_box {   /* menu*/
    display: block;
    position: fixed; 
    color: #fff;
    top: 0;
    left: -100%;
    width: 90%;
    height: 100%;
    visibility: hidden;
 
    padding-top:130px;
    list-style: none;
 
    /* background: rgb(15, 175, 15);*/
    box-shadow: 1px 0px 6px rgba(40, 74, 105, 0.2);
    transition-duration: .25s;
    /*border-right: 1px #f2f3f4 solid;*/
    box-shadow: 0 0 2px green(0, 0, 0, 0.5);
    z-index: 99989;
    overflow:hidden;
    
  }
}




@media (max-width: 400px) {
  .menu_box {   /* menu*/
    display: block;
    position: fixed; 
    color: #fff;
    top: 0;
    left: -100%;
    width: 90%;
    height: 100%;
    visibility: hidden;
 
    padding-top:130px;
    list-style: none;
   
    /* background: rgb(15, 175, 15);*/
    box-shadow: 1px 0px 6px rgba(40, 74, 105, 0.2);
    transition-duration: .25s;
    /*border-right: 1px #f2f3f4 solid;*/
    box-shadow: 0 0 2px green(0, 0, 0, 0.5);
    z-index: 99989;
    overflow:hidden;
    
  }
}



#menu_toggle {
  opacity: 0;
}

#menu_toggle:checked~.menu_btn>span {

  transform: rotate(45deg);
}

#menu_toggle:checked~.menu_btn>span::before {
  top: 0;
  transform: rotate(0);
}

#menu_toggle:checked~.menu_btn>span::after {
  top: 0;
  transform: rotate(90deg);
}



#menu_toggle:checked~.menu_box {
  visibility: visible;
  left: 0;
 
  
}



#menu_toggle:checked~.menu_btn {
  width: 60px;
  height: 60px;
  background: none;
  
}

html body div.hamburger-menu label.menu_btn {
  display: flex;
  align-items: center;
  position: fixed;
   top: 0px;
  left: 0px; 
  width: 70px;
  height: 70px;
  cursor: pointer;
  z-index: 99990;
  padding-left: 20px;
  color:#ffffff; color: #3d3d3d;
   
}

.menu_btn>span,
.menu_btn>span::before,
.menu_btn>span::after {
  display: block;
  position: absolute;
  width: 25px;
  height: 4px;
  background-color:   #3d3d3d;
 
  transition-duration: .25s;
  
}

.menu_btn>span::before {
  content: '';
  top: -8px;
}

.menu_btn>span::after {
  content: '';
  top: 8px;
}


 





.menu_box .ul_li{
  list-style: none;
   
  margin:0px;
  text-align: left;
  padding:0px;

}

.menu_box .ul_li li {
   
  padding-top: 0px;
  padding-bottom: 0px;
 margin-left:0px;
}


.menu_box li.as {

  padding: 0px;
  margin: 0px;
  height: 10px;
}

.menu_box li.as a {
  border-left: none;

  border-top: 1px rgb(226, 228, 222) solid;
  width:80%;
  margin-left: 5%;
}



.menu_item i.fa {
  margin-left: 16px;
  margin-right: 15px;
  /* color: rgb(151, 153, 148);*/
  
  color: white;
}

.menu_item {
  display: block;
  padding: 5px;  
  color: white;
  font-family: 'Oswald';
  text-decoration: none;
  transition-duration: .25s;
  border:none;
 padding-left:15px;
 width:90%;
  margin: 4px;
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
 
}

 
@media (max-width: 480px) {

  .menu_item {
    display: block;
    padding: 5px;
    
    color: white;
    font-family: 'Oswald';
    text-decoration: none;
    transition-duration: .25s;
    border:none;
   padding-left:15px;
   width:90%;
    margin: 4px;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
    
  }}
 


 .menu_box li ul.submenu {
padding:0px;
  display: none;
  margin-left: -20px;
  list-style: none;
  height: auto;
  position: relative;
 
 
  width:auto;
   
}

.menu_box li ul.submenu li {

  margin: 5px;
  display: block;
  height: 50px;
  
}




label.submenu_btn {

  font-family: 'Oswald';

cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  transition-duration: .25s;
  position: relative;
  font-weight: 600;
}

.menu_box li ul.submenu li a {
  color: white;
  font-family: 'Oswald';
  font-size: 16px;
  margin: 5px;
  font-weight: 500;
  text-decoration: none;
  transition-duration: .25s;
  position: relative;
  margin-left:5px;
}



.menu_box li ul.submenu1 {
 
  display: none;
  margin-left: -20px;
  list-style: none;
  height: auto;
  position: relative;
  padding:0px;
 
 
}

.menu_box li ul.submenu1 li {

  margin: 5px;
  display: block;
  height: 50px;
  position: relative;
}




.menu_box li ul.submenu1 li a {
  color: white;
  font-family: 'Oswald';
  font-size: 16px;
  margin: 0px;
  font-weight: 500;
  text-decoration: none;
  transition-duration: .25s;
  position: relative;
  margin-left:5px;
}

 




#submenu_toggle {
  opacity: 1;
}



#submenu_toggle:checked~ul.submenu {
  display: block;
}


 

 

#submenu_toggle {
  display: none;
}

#submenu_toggle1:checked~ul.submenu1 {
  display: block;
}

 

 
   












#submenu_toggle1 {
  display: none;
}


#submenu_toggle2:checked~ul.submenu2 {
  display: block;
}

#submenu_toggle2 {
  display: none;
}



#submenu_toggle3:checked~ul.submenu3 {
  display: block;
}

#submenu_toggle3 {
  display: none;
}



label.chka{
  
 
   
}

/***********************    +    -     checked   ***************************/

    .subCat, .chka {
  padding-top:0px;
 margin:0;
 padding:0px;
 margin-left:12px;
cursor: pointer;
display: flex;
justify-content:flex-start;
align-items: center;

}

  .chka:before {
  
    margin-left:-22px;
    margin-right:30px;
 margin-top:0px;
 padding-top:2px;
content: "+";
font-size: 26px;
color: white;
 
  font-weight: 400;
 
}

 
  

      .subCat:checked ~ .chka:before{
content: '-';
margin-left:-19px;
}

 

 

input[type=checkbox].subCat {
  display:none;
  }


  .subCat:checked ~ ul.submenu {
    display: block !important;
    }
    







    .subCat1, .chka1 {
      padding-top:0px;
      
      margin-left:12px;
     cursor: pointer;
     display: flex;
     justify-content:flex-start;
     align-items: center; 
    }
    
      .chka1:before {
        margin-left:-22px;
        margin-right:30px;
        margin-top:0px;
        padding-top:2px;
       content: "+";
       font-size: 26px;
       color: white;
         font-weight: 400;
 
    }
    
     
      
    
          .subCat1:checked ~ .chka1:before{
    content: '-';
     
    margin-left:-19px;
    }
    
     
    
     
    
    input[type=checkbox].subCat1 {
      display:none;
      }

    .subCat1:checked ~ ul.submenu1 {
      display: block !important;
      }
      






li.pl{
  padding-left:20px;
  
   
}









/************************************    end Menu Burger **************************************************************/


  }

