.container{
   text-transform:none;
   cursor: auto;
   height:100%;
   padding:5px;
}
.container p{
    margin:2px;
    text-indent: 20px;
    color:#3d3d3d
}
.js_top{
     width:100%;
    height:auto;
  
    padding:5px;
    
    display: flex;
    flex-direction: row;
    }

    .js_top  .ico_title{
        padding-top:10px;
        
        width:100%;
        display: flex;
        flex-direction: column;
        height:100%;
        justify-content: end;
        align-items: end;
        }

        .js_top  .img_title{
            
            padding:0px;
            margin-left:10px;
        
            display: flex;
            flex-direction: row;
           
            }



    .js_top .ico_title h2{  
        color:rgb(255, 135, 7);
       font-size: 18px;
       font-family: 'Oswald', sans-serif;
       font-weight: 400;
      
       }


       .js_top  .ico_title .js{  
        color:rgb(255, 135, 7);
       font-size: 18px;
       margin:0px;
       margin-left:7px;
       font-weight: 400;
      
       }

       .js{
        width:18px;
        height:22px;

       }
       .jsimg{
        border-radius: 50%;
       }

       .js_bottom{
        width:100%;
       height:auto;
       margin:0 auto;
       padding:7px;
       padding-top:0px;
       display: flex;
       flex-direction: column;
       }
       .js_bottom{
        
        text-align: justify;
       }

       .js_bottom{
        line-height: 20px;
       
       }
      
       .js_bottom  .loiha{
       list-style: none;
       display: flex;
       font-style: italic;
       color:aliceblue;
       text-align: center;
       color:#3d3d3d
              }
              .js_bottom ul.loiha li{
                margin:0;
                padding:2px;
                color:#3d3d3d
                 
              }

              .js_bottom .viki{
 text-align: right;
 color:rgb(255, 135, 7);
 margin-right:30px;
              }