.malumot{
    border:1px white solid;
    height:auto;  padding:0px;
margin:0px;
}


.pagination{
 
    float:right;
    margin:5px;
}

.page-link{
    color:#27ae60;
    font-size: 14px;
}
.active>.page-link{
    background-color: #27ae60;
    border:solid 1px #27ae60;
   
}
.faicons{
    color:black;
    font-size: 16px;
    padding:3px;
}

.aitem{
    max-width:768px;
 
    height:auto;
    margin:80px auto;
   
    
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.aitem .title{    
     width:100%;
     background-color:#27ae60;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px;
  color:white;
  font-weight: 400;
  text-transform: uppercase;
  font-family: 'Oswald';
  user-select: none;
}
 


.grid-aitem{
    display: grid;
    grid-template-columns: 100%; 
    grid-template-rows:  repeat(4,auto); 
    grid-gap:15px;
    
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    width:100%;
   height:auto;
    margin:0px auto;
    padding:25px;
     
}

.grid-aitem .item-item1{
    grid-column-start:1;
    grid-column-end:2;
    grid-row-start:1;
    grid-row-end:3;
  
     display: flex;
  width:100%;
     align-items: left;
     flex-direction: column;
     padding:5px;
     
}


.grid-aitem .item-item1 p{
    padding:10px 0px 0px 0px;
    margin:0px;
    text-align: left;
    font-weight: 200;
    font-family: 'Oswald';
    font-size: 16px;
}

.grid-aitem .item-item1 .o_p{
    font-family: 'Roboto';
    font-weight: 100;
     font-size: 14px;
}


.item-item1 .o_img{
    width:100px;
    
    border:2px solid #dddddd; 
    padding:5px;
}
.item-item1  .o_name{
margin:0px;
margin-bottom: 10px;
font-weight: 600; font-size: 14px;
}
 
.t_mukofot{
    
    padding:5px;
    margin-left:10px;
}
.grid-aitem .item-item2{
    grid-column-start:1;
    grid-column-end:2;
    grid-row-start:3;
    grid-row-end:4;
    padding:5px;
    font-size: 14px;
    font-family: 'Roboto';
    text-shadow: none;
}


.grid-aitem .item-item2 b{
    font-weight:bold;
}


.grid-aitem .t_title{
    width:100%;
    border-bottom:2px solid rgb(125, 125, 125);
    font-weight: 200;
    font-family: 'Oswald';
    margin-bottom: 10px;
    font-size: 15px;
}

.grid-aitem .t_matn{
font-weight: 200;font-family: 'Roboto';
margin-bottom: 7px;
font-family: 'Roboto';
font-size: 14px;
}

.grid-aitem .t_samt{
    font-size: 15px;
    margin-bottom: 7px;
    font-family: 'Oswald';
    }



.grid-aitem .item-item3{
    grid-column-start:1;
    grid-column-end:2;
    grid-row-start:4;
    grid-row-end:5;
    font-weight: 200;font-family: 'Roboto';
    padding:5px;
    font-size: 14px; 
}
 
.grid-aitem .item-item3 b{
    font-weight:bold;
}

.grid-aitem .item-item5{
    grid-column-start:1;
    grid-column-end:2;
    grid-row-start:5;
    grid-row-end:6;
    font-size: 14px;font-weight: 200;font-family: 'Roboto';
      padding:5px;
    
}
.grid-aitem .item-item6{
    grid-column-start:1;
    font-weight: 200;
    font-family: 'Roboto';
    grid-column-end:2;
    grid-row-start:6;
    grid-row-end:7;
    padding:5px;
    font-size: 14px;
    height:auto;  
}
 
.grid-aitem .item-item6 textarea{
    width:100%;
     
    height:450px;
    overflow: auto;
    border:1px solid;
    font-size: 12px;
    font-family: 'Roboto';
    padding:5px;
}


 

.grid-aitem .item-item6 .t_title a{
    padding:0px;
    margin:0px;
}

.grid-aitem .item-item6 .t_title .m_all{
   padding:2px 5px 2px 5px;
   margin-left:2px;
   font-weight: 400;
   font-family: 'Roboto';
   font-size: 12px;
    margin-left:3px;
    margin-bottom:5px;
   
}

.grid-aitem .item-item6 .t_title .m_all:hover{
    color:white;
    
    transition: 1s;

}



@media (max-width: 992px) {

    .aitem{
        max-width:750px;
     
        height:auto;
        margin:90px auto;
       
        
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }



}

 
 
@media (max-width: 768px) {

    .aitem{
        max-width:500px;
     
        height:auto;
        margin:90px auto;
       
        
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }



}
  
  
@media (max-width: 550px) {

    .aitem{
        width:95%;
     
        height:auto;
        margin:90px auto;
       
        
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }



}
  