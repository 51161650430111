

.mBackground{
    width:100%;
height:100%;
position: fixed;
display: flex;
justify-content: center;
align-items: center;
 
z-index:9999999;
font-family: 'Montserrat';
font-size: 14px;
 
}


.mContainer{
    width:600px;
    height:95%;
    border-radius: 12px;
    background-color: transparent; 
    box-shadow: none; 

    display: flex;
    flex-direction: column;
    padding:10px;
     
}

.mContainer span{
    font-size: 12px;
    text-align: center;
    width:100%; 
}

.titleCloseBtnSignUp{
    display: flex;
    justify-content: flex-end;
   
     align-items: center;
     padding:0px;
}

 
 
 .bx{
    background-color: transparent;
    border:none;
    font-size: 30px;
 }

 
.mBackground .bg{
    height:90%;
    background-color: transparent;
    width:100%;
  
    display: flex;
    justify-content: center;
   color:white;
   flex-direction: column;
 margin-bottom:10px;
   
 overflow-y: scroll;
  
 
}

 .titleCloseBtnSignUp  h5{
    width:100%;
    text-align: center;
    color:#3d3d3d;
    padding:0px;
     font-size:16px
}

.formSignUp{
    width:90%;
    height:100%;
  color:white;
  padding-right:0px;
  
}

.formSignUp label{
    font-weight: 300; 
font-size: 12px;
    color:#3d3d3d;
    font-weight: bold;
}
.formSignUp input{
    background: transparent;
    height:35px;
    border:none;
    outline: none;
  
    border-bottom:1px solid  grey; 




    
}





.formSignUp input:focus ~ label, .formSignUp input:focus ~ :valid{
top:-5;
}

.formSignUp input:focus{
    background: transparent;
    border:none;  
}
.formSignUp input::placeholder{
    color:rgb(182, 182, 182);
    opacity: 1;
    font-size: 13px;   
}

.formSignUp input::-ms-input-placeholder{
    color:rgb(182, 182, 182);   opacity: 1;
}

 
.formSignUp .form-control {
    color:#3d3d3d;
    font-size: 13px;
    
}
 

.bgfooter{
    
    width:92%;
    height:80px;
    margin:0 auto;
    justify-content: center;
align-items: center;
padding:0px;
display: flex;
}
.bgfooter button{
font-size: 13px;
height:40px;
justify-content: center;
align-items: center;
padding:0px;
display: flex;
margin:2px;
 background : #27ae60;
 color:#ffffff;
 border:none;
 
}

 
.fai{
    color:#ffffff;
    margin-left:10px;
    font-size: 22px;
    
    transform: translateX(5px);
}
.bgfooter button:hover .fai{
    color:white;
}


.bgfooter .btn-default{
    font-size: 13px;
    height:40px;
    background-color: none;
    display: flex;  align-items: center;
    justify-content: center;
    margin:2px;
}
 
@media (max-width: 605px) {
    .mContainer{
        width:90%;
        height:95%;
        border-radius: 7px;
        background-color: transparent; 
        box-shadow: rgba(0,0,0,0.35) 0px 5px 15px; 
     
        display: flex;
        flex-direction: column;
        padding:10px;
         
    }  
}

@media (max-width: 420px) {

    .mContainer{
        width:95%;
        height:95%;
        border-radius: 7px;
        background-color: transparent; 
        box-shadow: rgba(0,0,0,0.35) 0px 5px 15px; 
     
        display: flex;
        flex-direction: column;
        padding:10px;
         
    }  

    .bgfooter{
    
        width:92%;
        height:100px;
        margin:0 auto;
        
    padding:0px;
    display: block;
  padding-right:5px;
    padding-top:10px;
    }


    .bgfooter button{
        font-size: 13px;
        height:40px;
        justify-content: center;
        align-items: center;
        padding:0px;
        display: flex;
        margin:2px;
         background : #27ae60;
         color:#ffffff;
         border:none;
         
        }
        
         
        .fai{
            color:#ffffff;
            margin-left:10px;
            font-size: 22px;
            
            transform: translateX(5px);
        }
        .bgfooter button:hover .fai{
            color:white;
        }
        
        
        .bgfooter .btn-default{
            font-size: 13px;
            height:40px;
            background-color: none;
            display: flex;  align-items: center;
            justify-content: center;
            margin:2px;
        }
}