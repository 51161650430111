.inl{
    position: fixed; 
   width: 45px;
   left: 90%;
   bottom: 30px;
   height: 45px;
    
   z-index: 1;
   cursor: pointer;
   color:#27ae60;;
 border-radius: 3px;
 border:1px solid #27ae60;
 box-shadow: 0px 0px 1px 1px white;
 
}

 

@media (max-width: 640px) {
    .inl{
        position: fixed; 
       width: 45px;
       left: 85%;
       bottom: 30px;
       height: 45px;
        
       z-index: 1;
       cursor: pointer;
       
    }


}