.footer{
    width:100%;
    height:auto;
   
    background-color: #1d2636;
    
      -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
       -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                               supported by Chrome, Edge, Opera and Firefox */                  
                            
}
.footer_top{
    max-width:1170px;
    height:300px;
    margin:0 auto;
    padding:0px;
   
   display: flex;
   flex-direction: row;
    }
    .footer_top .lef{
        width:70%;
       
        
        position:relative;
         padding-top:40px;
         padding-left:30px;
    }

   



    .footer_top .lef h2{  
     
     
      color:#fff;
    font-size: 18px;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
     float:left;
    text-transform: uppercase; 
    }

    .faicon{
      
      color:rgb(230, 230, 230);  
        font-size: 13px;
       margin-top:-3px;
       padding:0px;
    }





    .footer_top .faicon_mail{
        
      color:rgb(255, 135, 7);
        
      
    }
.footer_line{
    
    height:100px;
    position: relative;
  border-top:1px solid grey;
  max-width: 1170px;
  margin:0 auto;
    
  
}
 
.footer_top .lef table{
  
    margin-top:40px;
    margin-left:0px;
     
}

.footer_top .lef table tr td{
  
    padding:2px;
    
}

.footer .lef table tr td.textfooter {
    line-height: 1em;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 13px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    color:rgb(230, 230, 230);  
   padding:5px;
   padding-top:8px; 
   padding-left:10px;
}


.footer .lef table tr td.td_img {
  padding:0px; 
}
 

 
.footer_top .lef .amail{
  color:rgb(255, 135, 7);
    text-decoration: none;
  font-weight: 600;
}


.footer_top .righ{
    width:30%;
    
    padding:15px;     
    display: flex;

    
}
 
 
 
 
 
  

    
   
  
    

  

 
  /********************/

 
 

.footer_line ul{
  list-style: none;
  font-weight: 400;
  font-family: Montserrat;
  font-size: 13px;
  color:rgb(230, 230, 230);  
  padding:0px;
  padding-top:10px;
  padding-left:40px;
display: flex;
 
margin:0px;
}

 

 
 
 .footer_title{
 display: flex;
flex-direction: column;
justify-content:end;
align-items:end;
 padding:5px;
margin:0px;
  width:100%; 
 
  height:auto;
 }
 
 
  


 
 

 
 


.footer_title  ul {
  list-style: none;
 
  font-family: Montserrat;
  font-size: 12px;
  color:white;  
  padding:0px;
   width:auto;
display: flex;
 flex-direction: column;
 justify-content: center;
margin:0px;
border-radius:5px ;


}

.footer_title  ul li{
  display: flex;
  flex-direction: row;
  justify-content: center;
 padding:7px;
 
font-size: 13px; 
}


.footer_title  ul li.li{
  display: flex;
  flex-direction: row;
  justify-content: center;
 padding:10px 15px 10px 15px;
 border-radius:5px ;
border:1px rgba(97, 97, 97, 0.192) solid;
font-size: 14px; 
}

.footer_title  ul li .vertline{
  height:100%;
  margin-left:15px;margin-right:15px;
  border-right:1px rgba(97, 97, 97, 0.192) solid;
}
.footer_title  ul li img { 
 padding:5px;
  cursor: pointer;
  transition: 1s;
  border-radius:5px ;  
   
  
}


.footer_title  ul li span{
  padding:0px;
 
  width:50%;
  text-align: center;
}




.footer_title  ul li img:hover { 
  border-radius:5px ;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(194, 198, 202, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(209, 216, 221, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(201, 206, 209, 0.2);
 transition: 1s;
}
.footer_title  ul li img.boxsh{
  -webkit-box-shadow: 0px 5px 10px 2px rgba(194, 198, 202, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(209, 216, 221, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(201, 206, 209, 0.2);
}


/******************************      ******************************************************/
@media (max-width:640px){
  .footer_top{
    width:100%;
    height:auto;
    margin:0 auto;
    padding:0px;
   
   display: flex;
   flex-direction: column;
    }
    .footer_top .lef{
        width:100%;
       
      
        position:relative;
         padding-top:40px;
         padding-left:30px;
    }

    .footer_top .righ{
      width:100%;
       
  }


  
.footer .lef table tr td.textfooter {
  line-height: 1em;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
 
 padding:5px;
 padding-top:8px; 
 padding-left:10px;
  
}
}