
div.kafedra {
	width: 100%;
	margin: 0px auto;
    padding:0px;
	
	height:auto;
	background:white;

}
div.sc2 {
	width: 100%;
	margin: 0px auto;
    padding:10px;
	height:auto;
	padding-top:60px;
}

div.sc2  h1 {
	text-transform: uppercase;
	font-family: 'Oswald';
	font-weight: 600;
	text-shadow: none;
	color: #3d3d3d;
	position: relative;
	
	font-size: 16px;
	margin:10px;margin-left: 50px;
}

.sc2 .txt{
	max-width:1170px;
	margin:0 auto;
}



  div.txt div.lf {

	float: left;
	width: 535px;
	margin-bottom: 5px;

}

  div.txt .abc{
	width:90px;
	height:380px;
	float:right;
}
  div.txt div.lf div.lff {
	border-top: 3px rgb(255, 255, 255) solid;
	border-right: 3px #fff solid;

	float: left;
	width: 520px;
	padding-top: 14px;
	border-top-right-radius: 10px;
	padding-bottom: 1px;
	margin-top: 5px;
	margin-bottom: 0px;
}


  div.txt p {
	text-indent: 40px;
	margin: 5px;
	font-size: 14px;
	font-family: 'Montserrat';
	line-height: 24px;

}

  div.txt p img{
		margin:10px;
}
  div.txt div.lf div.atb {
	background:  #1d2636;
	width: 505px;
	height: 30px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	text-align: center;
	font-family: 'Montserrat';
	font-weight: 600;
	padding-top: 5px;
	color: #fff;
	font-size: 0.7rem;
}


  div.txt div.lf table tr td {
	margin-left: 25px;
	padding-left: 10px;
	font-family: 'Montserrat';
	font-weight: 400;
	font-size: 14px;
	color: rgb(97, 97, 97);
	text-align: left;
	cursor: pointer;
	padding-top: 6px;
	width: 500px;
	border-bottom: 1.5px #fff solid;
}

.tb {
	width: 505px;
	box-shadow: 1px 3px 10px -3px rgb(163, 184, 159);
}

@media (max-width: 990px){
	div.kafedra {
		width: 100%;
		margin: 0px auto;
		padding:0px;
		height:auto;
	 
	}
	div.sc2 {
		width: 100%;
		margin: 0px auto;
		padding:10px;
		padding-top:80px;
		height:auto;
		 
	} 
		  
	} 

 

@media (max-width: 768px){
	.sc2 {
		max-width: 768px;
		margin: 200px auto;
		padding:10px;
		 
	}
	
	.sc2  h1 {
		text-transform: uppercase;
		font-family: 'Oswald';
		font-weight: 600;
		text-shadow: none;
		 
		position: relative;
		margin-left: 0px;
		font-size: 18px;
	}
	
	.sc2  div.txt div.lf {
	 
		 
		width: 100%;
		margin: 0px;
		padding:15px;
	
	}
	
	.sc2  div.txt .abc{  /* photo*/
		width:85px;
		height:380px;
		float:right; 
	}
	.sc2  div.txt div.lf div.lff {
		border:0px;
		float: left;
		width: 100%; 
		padding:0px;
		border-top-right-radius: 10px;
		margin:0px;
	}
	
	
	.sc2  div.txt p {
		text-indent: 40px;
		margin: 5px;
		font-size: 14px;
		font-family: 'Montserrat';
		line-height: 24px;
	
	}
	
	.sc2 div.txt p img{
			margin:10px;
	}
	.sc2  div.txt div.lf div.atb {
		 
		width: 100%;
		height: 30px;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
		text-align: center;
		font-family: 'Montserrat';
		font-weight: 600;
		padding-top: 5px;
		color: #fff;
		font-size: 0.7rem;
	}
	
	
	.sc2  div.txt div.lf table tr td {
		margin-left: 25px;
		padding-left: 10px;
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 14px;
		color: rgb(97, 97, 97);
		text-align: left;
		cursor: pointer;
		padding-top: 6px;
		width: 100%;
		border-bottom: 1.5px #fff solid;
	}
	
	.tb {
		width: 100%;
		box-shadow: 1px 3px 10px -3px rgb(163, 184, 159);
	}
	


 



}
