.modalBackground{
    width:100%;
height:100vh;
position: absolute;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(152, 161, 142, 0.911);
z-index:9999999;
padding:10px;
}


.modalContainer{
     width:600px;
    height:90%;
    border-radius: 12px;
    background-color: white;
    
    box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding:10px;
    font-family: 'Montserrat';
    font-size: 13px;
  padding:10px;
}

 


.titleCloseBtnModal{
     padding:0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
}

 



.titleCloseBtnModal button{
background-color: transparent;
border:none;
font-size: 30px;
cursor: pointer;
color:black;
 

}


.modalBackground .jdkk{
 height:85%;
 overflow-y: scroll;
 
 font-size: 12px;
 padding:10px;
 }

 .modalBackground p{
  
     display: flex;
     justify-content: center;
     align-items: center;
     padding:5px;
     font-weight: 600;
     }

     .jdkk table{
        width:100%;
     }
     .jdkk table tr td {
        border:1px rgb(71, 71, 71) solid;
        padding:2px;
     }
     .jdkk table tr.ttd td{
        font-weight: 700;
         border:none;
         padding-top:15px;
    }





     .jdkk table .tras{
    font-weight: 700;
     
}

.body table tr td{ 
    
    border-bottom: 1px rgb(199, 199, 199) solid;;
}

 

 

  
