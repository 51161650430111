
.habar{
   background-color: #f7f9fc;
width:100%;
height:auto;
}
 

.item_2{
   grid-column-start:1;
   grid-column-end:2;
   grid-row-start:2;
   grid-row-end:3;
    padding:5px;
    
    background-color: #f7f9fc;
    height:480px;
    max-width:1170px;
    margin:0 auto;
 }
 
 .section_2{
 display: grid;
 grid-template-columns: 50% 50%; 
 grid-template-rows: 1fr; 
 width:100%;
 margin:0 auto;
 padding:0px;
  
 height:100%; 
 
 }
 
 
 
 
 
 
 
 .section_2 .s1{
             grid-column-start:1;
        grid-column-end:2;
        grid-row-start:1;
        grid-row-end:2;
        padding:5px;
         
         padding-top:15px;
    }
 
    .section_2 .ico2  {
       display: flex;
       align-items: center;
       justify-content: flex-start; 
        width:60px;                                 
       padding:10px;
       color:  #3d3d3d; 
       font-size: 42px;
    }
 
    
    .section_2 .title2{
        width:auto; font-weight: bolder;
        text-transform: uppercase;
        font-size: 16px;  color:  #3d3d3d;  
        padding:10px;
        font-family: 'Oswald' ;
        display: flex;
        align-items: center;
        justify-content: flex-start; 
        
    }
    
    .section_2 .text2{
       width:100%; 
       color: #3d3d3d;
       
       padding:5px;
       font-size: 14px;
       font-family: 'Montserrat' ;
        padding:10px;
       
    }                       
 
    .section_2 .s2{
            grid-column-start:2;
            grid-column-end:3;
            grid-row-start:1;
        grid-row-end:2;
            padding:5px;
            
            padding-top:15px;
             
           }
            
 
           .section_2 .section_3{
             
             height:auto;
              text-align: center;
              padding:5px;
              width:100%;
           }
             
           .section_2 .section_3 .elon{
             padding:0px;
            height:100%;
           
             width:100%;
             display: flex;
          }
          .section_2 .section_3 .elon .left{
             
            width:50%;
            
         clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%);
           height:250px;
           background-color: #f7f9fc;
          }


          .section_2 .section_3 .elon .right{
             padding:10px;
            padding-top:30px;
            height:250px;
            display: block;
             width:50%;
            
             
             align-items: center;
          }


        .elon .right .title{
         font-family: 'Oswald' ;
         color:#3d3d3d;
         font-weight: 600;
         font-size: 14px;
     text-transform: uppercase;
     width:100%;
        }
        .elon .right .msgline{
         background-color:#2D6B9F;
         width:100px;
         height:3px;
         margin:10px 0 10px 0;
         
        }
        .elon .right .msg0{
         background-color: #f7f9fc;
         font-weight: 500;
         font-size: 15px;
       text-align: left;
       line-height: 1.2;
       margin-top:10px;
       font-family: 'Oswald' ;
        }
        .elon .right .msg1{
         background-color: #f7f9fc;
         font-weight: 400;
         font-size: 14px;
       text-align: left;
       margin-top:10px;
        }
        .elon  .right .msg2{
         background-color: #f7f9fc;
         font-weight: 400;
         font-size: 14px;
         color:#3d3d3d; 
         text-align: left;
         margin-top:10px;
        }
 
          .section_2 .section_3 .carousel{
             
             height:100%;
             padding:5px;
          }
 
 
         
 
 
          .section_2 .section_3  .carousel .carousel-indicators [data-bs-target] {
           box-sizing: content-box;
           flex: 0 1 auto;
           width: 30px;
           height: 3px;
           padding: 0;
           background-color:#647a8d;
          } 
       
          
 
          .section_2 .section_3 .carousel .habar_1{
            background-color: #f7f9fc;
           height:100%;
           display: flex;
           flex-direction: row;
           justify-content: center;
           align-items: center;
        }
 
        .section_2 .section_4 .carousel .habar_1{
         background-color: #f7f9fc;
         height:100%;
         display: flex;
         flex-direction: row;
         justify-content: center;
         align-items: center;
      }
 
 
      .habar_1 .hb3{
         height:250px;
      }
      .habar_1 .hb4{
         height:250px;
      }
 
 
        .habar_1 .hb1{
         width:45%;
        
         clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%);
           height:250px;
           background-color: #f7f9fc;
          
        }
         
        
 
 
        .habar_1 .hb2{
         width:55%;
         background-color: #f7f9fc;
         height:250px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items:flex-start;
 padding:10px;
        }
 
        .habar_1 .hb2 .title{
         font-family: 'Oswald' ;
         color:#3d3d3d;
         font-weight: 600;
         font-size: 16px;
     text-transform: uppercase;
        }
        .habar_1 .hb2  .msgline{
         background-color:#2D6B9F;
         width:100px;
         height:3px;
         margin:10px 0 10px 0;
         
        }
        .habar_1 .hb2  .msg1{
         background-color: #f7f9fc;
         font-weight: 400;
         font-size: 14px;
       text-align: left;
        }
 
        .habar_1 .hb2  .msg2{
         background-color: #f7f9fc;
         font-weight: 400;
         font-size: 14px;
         color:#3d3d3d; 
         text-align: left;
        }
 
 
        .section_2 .section_4{
        
         height:auto;
          text-align: center;
          padding:5px;
          width:100%;
          display: flex;
          flex-direction: row;
       }
         
       .section_2 .section_4 .carousel{
         
         height:100%;
         padding:5px;
      }
 
       
         

 

@media (max-width: 990px) {

   .item_2{
      max-width:990px;
      margin:0 auto;
       padding:0px;
   
      
       height:450px;
       
         
      
         grid-column-start:1;
         grid-column-end:2;
         grid-row-start:2;
         grid-row-end:3;
          padding:10px;
       
          
         
         
     
  }



    .habar_1 .hb1{
        width:45%;
       
        clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%);
          height:220px;
       
         
       }
        
       
  
  
       .habar_1 .hb2{
        width:55%;
       
        height:220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-start;
  padding:10px;
       }
       .habar_1 .hb3{
        width:33%;
       
        height:220px; cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-start;
  padding:2px;
  
  
       }
       .habar_1 .hb4{
        width:33%;
      
        height:220px; cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-start;
  padding:2px;
  
  
       }
  
  
   
          
  .section_2{
    display: grid;
    grid-template-columns: 50% 50%; 
    grid-template-rows: 1fr; 
    max-width:990px;
    margin:0 auto;
    padding:0px;
     
    height:100%; 
    
    }
    
    .section_2 .section_3 .elon{
      padding:0px;
     height:100%;
    
      width:100%;
      display: flex;
   }
   .section_2 .section_3 .elon .left{
      
     width:50%;
     
  clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%);
    height:220px;
    background-color: #f7f9fc;
   }


   .section_2 .section_3 .elon .right{
      padding:10px;
     padding-top:30px;
     height:220px;
     display: block;
      width:50%;
     
      
      align-items: center;
   }


 .elon .right .title{
  font-family: 'Oswald' ;
  color:#3d3d3d;
  font-weight: 500;
  font-size: 13px;
text-transform: uppercase;
width:100%;
 }
 .elon .right .msgline{
  background-color:#2D6B9F;
  width:100px;
  height:3px;
  margin:10px 0 10px 0;
  
 }
 .elon .right .msg0{
  background-color: #f7f9fc;
  font-weight: 500;
  font-size: 14px;
text-align: left;
line-height: 1.2;
margin-top:10px;
font-family: 'Oswald' ;
 }
 .elon .right .msg1{
  background-color: #f7f9fc;
  font-weight: 400;
  font-size: 13px;
text-align: left;
margin-top:10px;
line-height: 1;
 }
 .elon  .right .msg2{
  background-color: #f7f9fc;
  font-weight: 400;
  font-size: 13px;
  color:#3d3d3d; 
  text-align: left;
  margin-top:15px;
 }

    
   
 .habar_1 .hb2 .title{
   font-family: 'Oswald' ;
   color:#3d3d3d;
   font-weight: 600;
   font-size: 14px;
text-transform: uppercase;
  }
  .habar_1 .hb2  .msgline{
   background-color:#2D6B9F;
   width:100px;
   height:3px;
   margin:10px 0 10px 0;
   
  }
  .habar_1 .hb2  .msg1{
   background-color: #f7f9fc;
   font-weight: 400;
   font-size: 13px;
 text-align: left;
  }

  .habar_1 .hb2  .msg2{
   background-color: #f7f9fc;
   font-weight: 400;
   font-size: 13px;
   color:#3d3d3d; 
   text-align: left;
  }

   
}

@media (max-width: 870px) {

   .item_2{
      max-width:870px;
      margin:0 auto;
       padding:0px;
   
      
       height:450px;
       
         
      
         grid-column-start:1;
         grid-column-end:2;
         grid-row-start:2;
         grid-row-end:3;
          padding:10px;
       
          
         
         
     
  }



    .habar_1 .hb1{
        width:45%;
       
        clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%);
          height:220px;
       
         
       }
        
       
  
  
       .habar_1 .hb2{
        width:55%;
        
        height:220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-start;
  padding:10px;
       }
       .habar_1 .hb3{
        width:33%;
        
        height:220px; cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-start;
  padding:2px;
  
  
       }
       .habar_1 .hb4{
        width:33%;
      
        height:220px; cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-start;
  padding:2px;
  
  
       }
  
  
   
          
  .section_2{
    display: grid;
    grid-template-columns: 50% 50%; 
    grid-template-rows: 1fr; 
    width:100%;
    margin:0 auto;
    padding:0px;
     
    height:100%; 
   
    }
    
    .section_2 .section_3 .elon{
      padding:0px;
     height:100%;
    
      width:100%;
      display: flex;
   }
   .section_2 .section_3 .elon .left{
      
     width:50%;
     
  clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%);
    height:220px;
    background-color: #f7f9fc;
   }


   .section_2 .section_3 .elon .right{
      padding:10px;
     padding-top:10px;
     height:220px;
     display: block;
      width:50%;
     
      
      align-items: center;
   }


 .elon .right .title{
  font-family: 'Oswald' ;
  color:#3d3d3d;
  font-weight: 500;
  font-size: 12px;
text-transform: uppercase;
width:100%;
 }
 .elon .right .msgline{
  background-color:#2D6B9F;
  width:100px;
  height:3px;
  margin:10px 0 10px 0;
  
 }
 .elon .right .msg0{
  background-color: #f7f9fc;
  font-weight: 500;
  font-size: 12px;
text-align: left;
line-height: 1.2;
margin-top:5px;
font-family: 'Oswald' ;
 }
 .elon .right .msg1{
  background-color: #f7f9fc;
  font-weight: 400;
  font-size: 12px;
text-align: left;
margin-top:10px;
line-height: 1;
 }
 .elon  .right .msg2{
  background-color: #f7f9fc;
  font-weight: 400;
  font-size: 12px;
  color:#3d3d3d; 
  text-align: left;
  margin-top:15px;
 }

    
   
 .habar_1 .hb2 .title{
   font-family: 'Oswald' ;
   color:#3d3d3d;
   font-weight: 600;
   font-size: 12px;
text-transform: uppercase;
  }
  .habar_1 .hb2  .msgline{
   background-color:#2D6B9F;
   width:100px;
   height:3px;
   margin:10px 0 10px 0;
   
  }
  .habar_1 .hb2  .msg1{
   background-color: #f7f9fc;
   font-weight: 400;
   font-size: 12px;
 text-align: left;
  }

  .habar_1 .hb2  .msg2{
   background-color: #f7f9fc;
   font-weight: 400;
   font-size: 12px;
   color:#3d3d3d; 
   text-align: left;
  } 
             

}



@media (max-width: 780px) { /*********************************************   768   ***************************************************************/
   .item_2{
      max-width:780px;
      margin:0 auto;
       padding:0px; 
             height:900px;
          grid-column-start:1;
         grid-column-end:2;
         grid-row-start:2;
         grid-row-end:3;
          padding:10px;
             }


 
  .section_2{
   display: block; 
    width:100%;
    margin:0 auto;
    padding:0px;
 
    height:100%; 
       }
    
    .section_2 .section_3 .elon{
      padding:0px;
     height:100%;    
      width:100%;
      display: flex;
      border:1px rgb(255, 255, 255) solid;
   }
   .section_2 .section_3 .elon .left{
            width:43%;
       clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    height:220px;
    background-color: #f7f9fc;
   }


   .section_2 .section_3 .elon .right{
      padding:10px;
      padding-top:30px;
     height:220px;
     display: block;
      width:57%;
        
      align-items: center;
   }


 .elon .right .title{
  font-family: 'Oswald' ;
  color:#3d3d3d;
  font-weight: 500;
  font-size: 14px;
text-transform: uppercase;
width:100%;
 }
 .elon .right .msgline{
  background-color:#2D6B9F;
  width:100px;
  height:3px;
  margin:10px 0 10px 0;
  
 }
 .elon .right .msg0{
  background-color: #f7f9fc;
  font-weight: 500;
  font-size: 15px;
text-align: left;
line-height: 1.2;
margin-top:5px;
font-family: 'Oswald' ;
 }
 .elon .right .msg1{
  background-color: #f7f9fc;
  font-weight: 400;
  font-size: 14px;
text-align: left;
margin-top:10px;
line-height: 1;
 }
 .elon  .right .msg2{
  background-color: #f7f9fc;
  font-weight: 400;
  font-size: 14px;
  color:#3d3d3d; 
  text-align: left;
  margin-top:15px;
 }

    
   
 .habar_1 .hb2 .title{
   font-family: 'Oswald' ;
   color:#3d3d3d;
   font-weight: 600;
   font-size: 14px;
text-transform: uppercase;
  }
  .habar_1 .hb2  .msgline{
   background-color:#2D6B9F;
   width:100px;
   height:3px;
   margin:10px 0 10px 0;
   
  }
  .habar_1 .hb2  .msg1{
   background-color: #f7f9fc;
   font-weight: 400;
   font-size: 14px;
 text-align: left;
  }

  .habar_1 .hb2  .msg2{
   background-color: #f7f9fc;
   font-weight: 400;
   font-size: 14px;
   color:#3d3d3d; 
   text-align: left;
  } 
 
}




@media (max-width: 640px) { /*********************************************   768   ***************************************************************/
  .item_2{
     max-width:640px;
     margin:0 auto;
      padding:0px; 
            height:900px;
         grid-column-start:1;
        grid-column-end:2;
        grid-row-start:2;
        grid-row-end:3;
         padding:5px;
            }



 .section_2{
  display: block; 
   width:100%;
   margin:0 auto;
   padding:0px;

   height:100%; 
      }
   
   .section_2 .section_3 .elon{
     padding:0px;
    height:100%;    
     width:100%;
     display: flex;
     border:1px rgb(255, 255, 255) solid;
  }
  .section_2 .section_3 .elon .left{
           width:45%;
      clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
   height:220px;
    
   background-color: #f7f9fc;
   padding:0px;
   padding-top:5px;
   padding-bottom:5px;
  }


  .section_2 .section_3 .elon .right{
     padding:10px;
     padding-top:30px;
    height:220px;
    display: block;
     width:55%;
      
     align-items: center;
  }


.elon .right .title{
 font-family: 'Oswald' ;
 color:#3d3d3d;
 font-weight: 500;
 font-size: 14px;
text-transform: uppercase;
width:100%;
}
.elon .right .msgline{
 background-color:#2D6B9F;
 width:100px;
 height:3px;
 margin:10px 0 10px 0;
 
}
.elon .right .msg0{
 background-color: #f7f9fc;
 font-weight: 500;
 font-size: 15px;
text-align: left;
line-height: 1.2;
margin-top:5px;
font-family: 'Oswald' ;
}
.elon .right .msg1{
 background-color: #f7f9fc;
 font-weight: 400;
 font-size: 14px;
text-align: left;
margin-top:10px;
line-height: 1;
}
.elon  .right .msg2{
 background-color: #f7f9fc;
 font-weight: 400;
 font-size: 14px;
 color:#3d3d3d; 
 text-align: left;
 margin-top:15px;
}

   
  
.habar_1 .hb2 .title{
  font-family: 'Oswald' ;
  color:#3d3d3d;
  font-weight: 600;
  font-size: 14px;
text-transform: uppercase;
 }
 .habar_1 .hb2  .msgline{
  background-color:#2D6B9F;
  width:100px;
  height:3px;
  margin:10px 0 10px 0;
  
 }
 .habar_1 .hb2  .msg1{
  background-color: #f7f9fc;
  font-weight: 400;
  font-size: 14px;
text-align: left;
 }

 .habar_1 .hb2  .msg2{
  background-color: #f7f9fc;
  font-weight: 400;
  font-size: 14px;
  color:#3d3d3d; 
  text-align: left;
 } 

}














@media (max-width: 560px){
  .item_2{
    max-width:560px;
    margin:0 auto;
     padding:0px; 
           height:900px;
        grid-column-start:1;
       grid-column-end:2;
       grid-row-start:2;
       grid-row-end:3;
        padding:5px;
           }



.section_2{
 display: block; 
  width:100%;
  margin:0 auto;
  padding:0px;

  height:100%; 
     }
  
  .section_2 .section_3 .elon{
    padding:0px;
   height:100%;    
    width:100%;
    display: flex;
    border:1px rgb(255, 255, 255) solid;
 }
 .section_2 .section_3 .elon .left{
          width:45%;
     clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
  height:220px;
   
  background-color: #f7f9fc;
  padding:0px;
  padding-top:5px;
  padding-bottom:5px;
 }


 .section_2 .section_3 .elon .right{
    padding:10px;
    padding-top:20px;
   height:220px;
   display: block;
    width:55%;
     
    align-items: center;
 }


.elon .right .title{
font-family: 'Oswald' ;
color:#3d3d3d;
font-weight: 500;
font-size: 14px;
text-transform: uppercase;
width:100%;
}
.elon .right .msgline{
background-color:#2D6B9F;
width:100px;
height:3px;
margin:10px 0 10px 0;

}
.elon .right .msg0{
background-color: #f7f9fc;
font-weight: 500;
font-size: 15px;
text-align: left;
line-height: 1.2;
margin-top:5px;
font-family: 'Oswald' ;
}
.elon .right .msg1{
background-color: #f7f9fc;
font-weight: 400;
font-size: 14px;
text-align: left;
margin-top:10px;
line-height: 1;
}
.elon  .right .msg2{
background-color: #f7f9fc;
font-weight: 400;
font-size: 14px;
color:#3d3d3d; 
text-align: left;
margin-top:15px;
}

  
 
.habar_1 .hb2 .title{
 font-family: 'Oswald' ;
 color:#3d3d3d;
 font-weight: 600;
 font-size: 14px;
text-transform: uppercase;
}
.habar_1 .hb2  .msgline{
 background-color:#2D6B9F;
 width:100px;
 height:3px;
 margin:10px 0 10px 0;
 
}
.habar_1 .hb2  .msg1{
 background-color: #f7f9fc;
 font-weight: 400;
 font-size: 14px;
text-align: left;
}

.habar_1 .hb2  .msg2{
 background-color: #f7f9fc;
 font-weight: 400;
 font-size: 14px;
 color:#3d3d3d; 
 text-align: left;
} 
}



@media (max-width: 486px) {       
        
  .item_2{
    max-width:486px;
    margin:0 auto;
     padding:0px; 
           height:800px;
        grid-column-start:1;
       grid-column-end:2;
       grid-row-start:2;
       grid-row-end:3;
        padding:5px;
      
           }



.section_2{
 display: block; 
  width:100%;
  margin:0 auto;
  padding:0px;
  height:100%; 
     }
  
  .section_2 .section_3 .elon{
    padding:0px;
   height:100%;    
    width:100%;
    display: flex;
    border:1px rgb(255, 255, 255) solid;
 }
 .section_2 .section_3 .elon .left{
          width:40%;
     clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
  height:200px;
   
  background-color: #f7f9fc;
  padding:0px;
  padding-top:5px;
  padding-bottom:5px;
 }


 .section_2 .section_3 .elon .right{
    padding:10px;
    padding-top:20px;
   height:200px;
   display: block;
    width:60%;
     
    align-items: center;
 }


.elon .right .title{
font-family: 'Oswald' ;
color:#3d3d3d;
font-weight: 500;
font-size: 13px;
text-transform: uppercase;
width:100%;
}
.elon .right .msgline{
background-color:#2D6B9F;
width:100px;
height:3px;
margin:10px 0 10px 0;

}
.elon .right .msg0{
background-color: #f7f9fc;
font-weight: 500;
font-size: 13px;
text-align: left;
line-height: 1.2;
margin-top:5px;
font-family: 'Oswald' ;
}
.elon .right .msg1{
background-color: #f7f9fc;
font-weight: 400;
font-size: 13px;
text-align: left;
margin-top:10px;
line-height: 1;
}
.elon  .right .msg2{
background-color: #f7f9fc;
font-weight: 400;
font-size: 13px;
color:#3d3d3d; 
text-align: left;
margin-top:15px;
}

  
 
.habar_1 .hb2 .title{
 font-family: 'Oswald' ;
 color:#3d3d3d;
 font-weight: 600;
 font-size: 13px;
text-transform: uppercase;
}
.habar_1 .hb2  .msgline{
 background-color:#2D6B9F;
 width:100px;
 height:3px;
 margin:10px 0 10px 0;
 
}
.habar_1 .hb2  .msg1{
 background-color: #f7f9fc;
 font-weight: 400;
 font-size: 13px;
text-align: left;
}

.habar_1 .hb2  .msg2{
 background-color: #f7f9fc;
 font-weight: 400;
 font-size: 13px;
 color:#3d3d3d; 
 text-align: left;
} 
       
        }
      

        