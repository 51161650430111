.modalBackgroundkad{
    width:100%;
height:100%;
position: fixed;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(152, 161, 142, 0.911);
z-index:9999999;
}


.modalContainerkad{
    width:500px;
    height:450px;
  font-family: 'Montserrat';
    border-radius: 13px;
    background-color: white;
  
    box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding:25px;
    font-family: 'Montserrat';font-size: 12px;
  
}

.modalContainerkad p{
    font-weight: 600;
}


.titleCloseBtnkad{
     padding:0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
}

 



.titleCloseBtnkad button{
background-color: transparent;
border:none;
font-size: 30px;
cursor: pointer;
color:black;
 

}


 .body{
    overflow-y: scroll;
    
    height:90%;
   
 }

   

.body table tr td{ 
    
    border-bottom: 1px rgb(199, 199, 199) solid;;
}




@media (max-width: 640px) {
    .modalBackgroundkad{
        width:100%;
    height:100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(154, 185, 231, 0.911);
    z-index:9999999;
    padding:10px;
    }

.modalContainerkad{
    width:100%;
    height:60vh;
    border-radius: 7px;
    background-color: white;
    box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding:15px;
}

.modalContainerkad .title{
    display: inline-block;
    text-align: center;
    margin-top:10px;
}


.titleCloseBtn{
    display: flex;
    justify-content: flex-end;
}


.titleCloseBtn button{
background-color: transparent;
border:none;
font-size: 25px;
cursor: pointer;
}

.modalContainerkad .body{
   
        overflow-y: scroll;
        
        height:90%;
       
   
    
    
    
}

 

  }

