
.sfik{
    width:100%;
    height:auto;
}
 
    .oid_kafedra{
      animation: 5s show ease;
      position:relative;
      max-width:1170px;
         margin:0 auto;
        height:100%;
      
        padding:5px;
    }
    
 
        
    
    
    

      .oid_kafedra  .samt {
        
    width:100%;
    height:auto;
    text-align: justify;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
        padding:15px;
        padding-top:20px;
padding-bottom:0px;
    }
    .oid_kafedra  .samt a {
    color:#3d3d3d; 
        font-size: 16px;
        font-family: 'Oswald', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        margin-top:10px;
    }
  .oid_kafedra  .samt .msa{
      padding-top:5px;
    }
   .oid_kafedra  .samt p{
      padding:0px;
      
      margin:5px;
      margin-left:0px;
    }





   .oid_kafedra  .samt .p_kafedra{
    padding:0px; 
     
    color:#111111;
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    text-align: justify; 
    }



  .oid_kafedra  .matn{
        padding:15px;   
        color:#111111;
        line-height: 24px;
        font-size: 14px;
        font-weight: 400;
        margin: 0px;
        font-family: 'Montserrat', sans-serif;
        text-align: justify; 
        width:100%;
         padding-top:0px;

        }

         .oid_kafedra  .matn b{
           
          color:#111111;
        }


        @media (max-width: 1024px) {
    
     
                     .oid_kafedra{
                         max-width:1024px;
                         margin:0 auto;
                        height:100%;
                    }
                   
                }

                @media (max-width: 990px) { 
                                    .oid_kafedra{
                                                max-width:990px;
                                                 margin:0 auto;
                                                height:100%;
                                            }
                                           
                                        }   
                                        @media (max-width: 870px) {                    
                                             .oid_kafedra{
                                                        max-width:870px;
                                                         margin:0 auto;
                                                        height:100%;
                                                    }}             
 @media (max-width: 768px) {
   .oid_kafedra{
                                                                 
                                                                     margin:0 auto;
                                                                    height:100%;
                                                                }}
                                                                
@media (max-width: 640px) {
      .oid_kafedra{
                max-width:640px;
                 margin:0 auto;
                height:100%;
               
            }
        }

        
@media (max-width: 486px) {     
  .oid_kafedra{
         max-width:480px;
          margin:0 auto;
         height:100%;
        
     }
    }