div.omuzgor{
  padding :30px;
 padding-bottom: 60px;
  width: 100%;

  height:auto;
  background-color: #f7f9fc;
   
}

 

  h1{
  width:100%;
    display:flex;
   font-size: 16px;
  justify-content: center;
   margin:0px;
padding:20px;
  color:#3d3d3d;
    font-family: 'Oswald', sans-serif;
  font-weight: 500;
text-transform: uppercase;
}

.omuz_div{
max-width:1170px;
margin:0px auto;
height:auto;
padding:10px;
 
   
}

.omuz_grid{
  width:98%;
 
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 240px 240px;
   grid-gap:10px;
   text-align: center;
 margin:0px auto;
 

}
 

  .all_a{
 text-decoration: none;
  width:100%;
  height:100%;
  padding:10px;
  
}

 


.omuz_grid .g11{
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
   padding:0px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
  
}



.g11,.g12,.g13,.g14,.g15,.g16,.g17,.g18,.g19,.g20{
box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  cursor: pointer;
  transition: 1s;
}

.g11:hover{
  background-color:#ffffff;
  box-shadow: rgba(73, 81, 72, 0.429) 2px 6px 12px -2px, rgba(48, 48, 48, 0.807) 2px 3px 7px -3px;
}
 
.g11:hover .gimg{
  border:1px rgb(125, 125, 125) solid;
  transition: 1s;
}

.g12:hover{
  background-color:#ffffff;
  box-shadow: rgba(73, 81, 72, 0.429) 2px 6px 12px -2px, rgba(48, 48, 48, 0.807) 2px 3px 7px -3px;
}
 
.g12:hover .gimg{
  border:1px rgb(125, 125, 125) solid;
  transition: 1s;
}




.g13:hover{
  background-color:#ffffff;
  box-shadow: rgba(73, 81, 72, 0.429) 2px 6px 12px -2px, rgba(48, 48, 48, 0.807) 2px 3px 7px -3px;
}
 
.g13:hover .gimg{
  border:1px rgb(125, 125, 125) solid;
  transition: 1s;
}



.g14:hover{
  background-color:#ffffff;
  box-shadow: rgba(73, 81, 72, 0.429) 2px 6px 12px -2px, rgba(48, 48, 48, 0.807) 2px 3px 7px -3px;
}
 
.g14:hover .gimg{
  border:1px rgb(125, 125, 125) solid;
  transition: 1s;
}


.g15:hover{
  background-color:#ffffff;
  box-shadow: rgba(73, 81, 72, 0.429) 2px 6px 12px -2px, rgba(48, 48, 48, 0.807) 2px 3px 7px -3px;
}
 
.g15:hover .gimg{
  border:1px rgb(125, 125, 125) solid;
  transition: 1s;
}


.g16:hover{
  background-color:#ffffff;
  box-shadow: rgba(73, 81, 72, 0.429) 2px 6px 12px -2px, rgba(48, 48, 48, 0.807) 2px 3px 7px -3px;
}
 
.g16:hover .gimg{
  border:1px rgb(125, 125, 125) solid;
  transition: 1s;
}

.g17:hover{
  background-color:#ffffff;
  box-shadow: rgba(73, 81, 72, 0.429) 2px 6px 12px -2px, rgba(48, 48, 48, 0.807) 2px 3px 7px -3px;
}
 
.g17:hover .gimg{
  border:1px rgb(125, 125, 125) solid;
  transition: 1s;
}

.g18:hover{
  background-color:#ffffff;
  box-shadow: rgba(73, 81, 72, 0.429) 2px 6px 12px -2px, rgba(48, 48, 48, 0.807) 2px 3px 7px -3px;
}
 
.g18:hover .gimg{
  border:1px rgb(125, 125, 125) solid;
  transition: 1s;
}

.g19:hover{
  background-color:#ffffff;
  box-shadow: rgba(73, 81, 72, 0.429) 2px 6px 12px -2px, rgba(48, 48, 48, 0.807) 2px 3px 7px -3px;
}
 
.g19:hover .gimg{
  border:1px rgb(125, 125, 125) solid;
  transition: 1s;
}

.g20:hover{
  background-color:#ffffff;
  box-shadow: rgba(73, 81, 72, 0.429) 2px 6px 12px -2px, rgba(48, 48, 48, 0.807) 2px 3px 7px -3px;
}
 
.g20:hover .gimg{
  border:1px rgb(125, 125, 125) solid;
  transition: 1s;
}


.omuz_grid  .gimg{
  height: 90px;
  width:90px;
  margin:0 auto;
   border:2px white solid;
  cursor: pointer;
  padding:2px;
   border-radius:50%;
  margin-bottom:10px;
   
  
}

.omuz_grid .gname{
  color:#3d3d3d;
  font-size: 13px;
  font-weight: 400;
   margin:0px;
   padding:0px;
  
   
  font-family: 'Montserrat'; 
  
}

.omuz_grid .gstatus{
  text-transform: none;
  color:#3d3d3d;
  font-size: 13px;
  font-weight: 400;
  margin:5px;
 
  font-family: 'Roboto', sans-serif; 
}

.omuz_grid .gabout{
   
  color:#807e7e;
  font-size: 13px;
  font-weight: 200;
  font-family: 'Roboto', sans-serif;
  
}









.omuz_grid .g12{
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  
  padding:0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.omuz_grid .g13{
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  padding:0px;
  display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.omuz_grid .g14{
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
  padding:0px;
 
}
.omuz_grid .g15{
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 2;
                         padding:0px;
                         display: flex;
                         flex-direction: column;
                         justify-content: center;
                         align-items: center;
}

.omuz_grid .g16{
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
                        padding:0px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
}
.omuz_grid .g17{
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  padding:0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}

.omuz_grid .g18{
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:0px;
 
}

.omuz_grid .g19{
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:0px;
   
}

.omuz_grid .g20{
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:0px;
  
}







/*******************************************************************************/


@media (max-width: 1060px){
  .omuz_div{
    max-width:98%;
    margin:0px auto;
    height:auto;
    padding:10px;
     
       
    }
    
    .omuz_grid{
      width:100%;
      margin:0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 240px 240px;
       grid-gap:10px;
       text-align: center;
           }
           div.sc1 h1{
            width:100%;
              display:flex;
             font-size: 16px;
            justify-content: center;
             margin:0px;
          padding:20px;
        
              font-family: 'Oswald', sans-serif;
            font-weight: 500;
          
          }



}

@media (max-width: 990px){
  .omuz_div{
    max-width:98%;
    margin:0px auto;
    height:auto;
    padding:5px;
     
       
    }
    
    .omuz_grid{
      width:100%;
      margin:0px auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 240px 240px ;
       grid-gap:10px;
       text-align: center;
     
    }

    div.sc1 h1{
      width:100%;
        display:flex;
       font-size: 16px;
      justify-content: center;
       margin:0px;
       padding:10px;
    padding-top:90px;
    
        font-family: 'Oswald', sans-serif;
      font-weight: 500;
    
    }
  }

  @media (max-width: 890px){
    .omuz_grid{
      width:100%;
      margin:0px auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 240px 240px 240px ;
       grid-gap:10px;
       text-align: center;
     
    }

    .omuz_grid .g11{
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .omuz_grid .g12{
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .omuz_grid .g13{
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .omuz_grid .g14{
      grid-column-start: 4;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .omuz_grid .g15{
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    .omuz_grid .g16{
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    .omuz_grid .g17{
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    .omuz_grid .g18{
      grid-column-start: 4;
      grid-column-end: 5;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    .omuz_grid .g19{
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: 4;
    }
    .omuz_grid .g20{
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 4;
    }





    }

    @media (max-width: 768px){

      .omuz_div{
        width:100%;
        margin:0px auto;
        height:auto;
        padding:0px;
        
           
        }


      .omuz_grid{
        width:90%;
        margin:0px auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr ;
        grid-template-rows: 230px 230px 230px 230px ;
         grid-gap:10px;
         text-align: center;
       
      }
  
      .omuz_grid .g11{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      .omuz_grid .g12{
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      .omuz_grid .g13{
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      .omuz_grid .g14{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
      }
      .omuz_grid .g15{
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
      }
      .omuz_grid .g16{
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
      }
      .omuz_grid .g17{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
      }
      .omuz_grid .g18{
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 4;
      }
      .omuz_grid .g19{
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
      }
      .omuz_grid .g20{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 4;
        grid-row-end: 5;
      }
      }

      @media (max-width: 640px){
       
        div.omuzgor{
          padding :10px;
        
          width: 100%;
        
          height:auto;
          background-color: #f7f9fc;
           
        }
        


        .omuz_div{
          width:100%;
          margin:0px auto;
          height:auto;
          padding:0px;
          
             
          }
       
       
       
        .omuz_grid{
          width:100%;
          margin:0px auto;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr ;
          grid-template-rows: 230px 230px 230px 230px ;
           grid-gap:10px;
           text-align: center;
         
        }
        }

        @media (max-width: 576px){
          
          .omuz_div{
            width:80%;
            margin:0px auto;
            height:auto;
            padding:0px;
            
               
            }
          
          .omuz_grid{
            width:90%;
            margin:0px auto;
            display: grid;
            grid-template-columns: 1fr 1fr   ;
            grid-template-rows: 230px 230px 230px 230px  230px;
             grid-gap:10px;
             text-align: center;
           
          }
      
          .omuz_grid .g11{
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
          }
          .omuz_grid .g12{
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;
          }
          .omuz_grid .g13{
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
          }
          .omuz_grid .g14{
            grid-column-start: 2;
            grid-column-end:3;
            grid-row-start: 2;
            grid-row-end: 3;
          }
          .omuz_grid .g15{
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 3;
            grid-row-end: 4;
          }
          .omuz_grid .g16{
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 3;
            grid-row-end: 4;
          }
          .omuz_grid .g17{
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 4;
            grid-row-end: 5;
          }
          .omuz_grid .g18{
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 4;
            grid-row-end: 5;
          }
          .omuz_grid .g19{
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 5;
            grid-row-end: 6;
          }
          .omuz_grid .g20{
            grid-column-start:2;
            grid-column-end: 3;
            grid-row-start: 5;
            grid-row-end: 6;
          }
          }
          @media (max-width: 500px){


            .omuz_div{
               width:100%;
              margin:0px auto;
              height:auto;
              padding:10px;
               
                 
              }
            .omuz_grid{
              width:90%;
              margin:0px auto;
              display: grid;
              grid-template-columns: 1fr 1fr   ;
              grid-template-rows: 230px 230px 230px 230px  230px;
               grid-gap:10px;
               text-align: center;
             
            }
        
            .omuz_grid .g11{
              grid-column-start: 1;
              grid-column-end: 2;
              grid-row-start: 1;
              grid-row-end: 2;
            }
            .omuz_grid .g12{
              grid-column-start: 2;
              grid-column-end: 3;
              grid-row-start: 1;
              grid-row-end: 2;
            }
            .omuz_grid .g13{
              grid-column-start: 1;
              grid-column-end: 2;
              grid-row-start: 2;
              grid-row-end: 3;
            }
            .omuz_grid .g14{
              grid-column-start: 2;
              grid-column-end:3;
              grid-row-start: 2;
              grid-row-end: 3;
            }
            .omuz_grid .g15{
              grid-column-start: 1;
              grid-column-end: 2;
              grid-row-start: 3;
              grid-row-end: 4;
            }
            .omuz_grid .g16{
              grid-column-start: 2;
              grid-column-end: 3;
              grid-row-start: 3;
              grid-row-end: 4;
            }
            .omuz_grid .g17{
              grid-column-start: 1;
              grid-column-end: 2;
              grid-row-start: 4;
              grid-row-end: 5;
            }
            .omuz_grid .g18{
              grid-column-start: 2;
              grid-column-end: 3;
              grid-row-start: 4;
              grid-row-end: 5;
            }
            .omuz_grid .g19{
              grid-column-start: 1;
              grid-column-end: 2;
              grid-row-start: 5;
              grid-row-end: 6;
            }
            .omuz_grid .g20{
              grid-column-start:2;
              grid-column-end: 3;
              grid-row-start: 5;
              grid-row-end: 6;
            }
          }
          @media (max-width: 420px){

            div.omuzgor{
              padding :10px;
            
              width: 100%;
            
              height:auto;
              background-color: #f7f9fc;
               
            }
            


            .omuz_div{
              width:100%;
              margin:0px auto;
              height:auto;
              padding:0px;
              
                 
              }

            .omuz_grid{
              width:100%;
              margin:0px auto;
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-template-rows: repeat(5, 220px);
               grid-gap:5px;
               text-align: center;
             
            }
        
            .omuz_grid .g11{
              grid-column-start: 1;
              grid-column-end: 2;
              grid-row-start: 1;
              grid-row-end: 2;
            }
            .omuz_grid .g12{
              grid-column-start: 2;
              grid-column-end: 3;
              grid-row-start: 1;
              grid-row-end: 2;
            }
            .omuz_grid .g13{
              grid-column-start: 2;
              grid-column-end: 3;
              grid-row-start: 2;
              grid-row-end:3;
            }
            .omuz_grid .g14{
              grid-column-start: 1;
              grid-column-end:2;
              grid-row-start: 2;
              grid-row-end: 3;
            }
            .omuz_grid .g15{
              grid-column-start: 1;
              grid-column-end: 2;
              grid-row-start: 3;
              grid-row-end: 4;
            }
            .omuz_grid .g16{
              grid-column-start: 2;
              grid-column-end: 3;
              grid-row-start: 3;
              grid-row-end: 4;
            }
            .omuz_grid .g17{
              grid-column-start: 1;
              grid-column-end: 2;
              grid-row-start: 4;
              grid-row-end: 5;
            }
            .omuz_grid .g18{
              grid-column-start: 2;
              grid-column-end: 3;
              grid-row-start: 4;
              grid-row-end: 5;
            }
            .omuz_grid .g19{
              grid-column-start: 1;
              grid-column-end: 2;
              grid-row-start: 5;
              grid-row-end: 6;
            }
            .omuz_grid .g20{
              grid-column-start:2;
              grid-column-end: 3;
              grid-row-start: 5;
              grid-row-end: 6;
            }




            .omuz_grid  .gimg{
              height: 80px;
              width:80px;
              margin:0 auto;
               border:2px white solid;
              cursor: pointer;
              padding:2px;
               border-radius:50%;
              margin-bottom:10px;
               
              
            }
            
            .omuz_grid .gname{
              color:#3d3d3d;
              font-size: 12px;
              font-weight: 400;
               margin:0px;
               padding:0px;
              
               
              font-family: 'Montserrat'; 
              
            }
            
            .omuz_grid .gstatus{
              text-transform: none;
              color:#3d3d3d;
              font-size: 12px;
              font-weight: 400;
              margin:5px;
             
              font-family: 'Roboto', sans-serif; 
            }
            
            .omuz_grid .gabout{
               
              color:#807e7e;
              font-size: 12px;
              font-weight: 200;
              font-family: 'Roboto', sans-serif;
              
            }
            
            
            
            












            }